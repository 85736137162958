import React, { lazy, Suspense } from 'react';
import RouteFallback from './fallback.routes';
import PrivateLayout from '../layouts/private.layouts';
import { Text } from '@chakra-ui/react';
const ExpensesContainer = lazy(() => import('../containers/AllHomeData/Expenses/ExpensesContainer')); 
const AllMerchantList = lazy(() => import('../containers/AllMerchant/AllmerchantList'));
const LeadsAllShipmentPage = lazy(() => import('../pages/LeadsAllShipment.page'));


const HomeRoutesConfig = {
  privateRoute: [
    {
      path: '',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="allMerchant">
          <AllMerchantList />
        </Suspense>
      ),
    },
    
    {
      path: '/allMerchant',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <AllMerchantList />
        </Suspense>
      ),
    },
    
    {
      path: '/allMerchant/lead',
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />}>
         <LeadsAllShipmentPage/>
        </Suspense>
      ),
    },

    {
      path: '/allMerchant/expenses',
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />}>
         <ExpensesContainer/>
        </Suspense>
      ),
    },
    
  ],
};

export default HomeRoutesConfig;
