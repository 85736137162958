import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { httpCall, GET_EXCEL_DATA_URL ,GET_EXCEL_URL} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
import {
  setExcelData,
  setIsExcelDataLoading,
} from '../slices/ExcelDownloadSlice';
import { getIsRouteB2bOrB2c } from '../slices/ComponentFunctionSlice';

export function* GetExcelData(params) {
  yield put(setIsExcelDataLoading(true));
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { payload, dataType } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const downloadExcelUrl = `${process.env.REACT_APP_BASEURL}${GET_EXCEL_URL}/${dataType}/${pathName}`;
  try {
    const ExcelData = yield call(httpCall, {
      url: downloadExcelUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (ExcelData?.success) {
      yield put(setExcelData(ExcelData?.data?.rows));
      yield put(setIsExcelDataLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsExcelDataLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsExcelDataLoading(false));
  }
}

export function* watchExcelDataSaga() {
  yield takeLatest('GET_EXCEL_DATA', GetExcelData);
}

export default function* ExcelDataSaga() {
  yield all([watchExcelDataSaga()]);
}
