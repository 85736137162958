import { createSlice } from '@reduxjs/toolkit';
export const WeightManagementDetailsSlice = createSlice({
  name: 'WeightManagementDetailsSlice',
  initialState: {
    weightManagement: [],
    isWeightDetailsLoading: false,
  },
  reducers: {
    
    setAllWeightManagement: (state, action) => {
      state.weightManagement = action.payload;
    },
    setWeightDetailsLoading: (state, action) => {
      state.isWeightDetailsLoading = action.payload;
    },
  },
});
export const {
  setAllWeightManagement,
  setWeightDetailsLoading,
} = WeightManagementDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getAllWeightManagement = state =>{
 return state.weightManagementDetailsSlice.weightManagement;
}
export const getWeightDetailsLoading = state =>
state.weightManagementDetailsSlice.isWeightDetailsLoading;
///we can also write thunks by hand , which may contain both sync and async logic

export default WeightManagementDetailsSlice.reducer;
