import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import { FaSpinner } from 'react-icons/fa';
const FallbackRoute = () => {
  return (
    <Flex alignItems={'center'} justifyContent={'center'} h={'80vh'} w={'80vw'}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
      <FaSpinner
        style={{
          position: 'absolute',
          fontSize: '2em',
        }}
      />
    </Flex>
  );
};
export default FallbackRoute;
