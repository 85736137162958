import React from 'react';
import {
  Avatar,
  HStack,
  Text,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Flex,
  useMediaQuery,
  MenuDivider,
} from '@chakra-ui/react';
import { COLOR } from '../../utils/constant';

import { toast } from "react-toastify";
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { HiOutlineLogout } from 'react-icons/hi';
import {
  getIsRouteB2bOrB2c,
  getIsTokenInvalid,
} from "../../redux/slices/ComponentFunctionSlice";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const UserMenu = ({ loggedUser }) => {
  const [isLessThan768] = useMediaQuery('(max-width: 769px)');
  const dispatch = useDispatch();
  const isTokenInvalid = useSelector(getIsTokenInvalid);
  const fullName = `${loggedUser.firstName} ${loggedUser.lastName}`;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isTokenInvalid) {
      toast.error(`Session Expire Please Login Again`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      handleLogout();
    }
  }, [isTokenInvalid]);

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    navigate('/login');
  };
  return (
    <HStack spacing={{ base: '0', md: '6' }}>
      <Flex alignItems={'center'}>
        <Menu>
          <MenuButton
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: 'none' }}
          >
            <HStack alignItems="center" color="white" gap={2}>
              <Avatar size={'sm'} name={fullName} />
              {!isLessThan768 && (
                <>
                  <Text fontSize="md" color="white" whiteSpace={'nowrap'}>
                    {fullName}
                  </Text>
                  <FiChevronDown />
                </>
              )}
            </HStack>
          </MenuButton>
          <MenuList bg={COLOR.blue} borderColor={COLOR.blue}>
            <MenuItem
              w={'95%'}
              mx={'auto'}
              rounded={'md'}
              _hover={{ bg: 'gray.500' }}
              bg="#274a69"
              color="white"
            >
              <Text color="white">
                User ID : &nbsp;
                {loggedUser?.empId}
              </Text>
            </MenuItem>{' '}
            <MenuDivider />{' '}
            <MenuItem
              w={'95%'}
              mx={'auto'}
              rounded={'md'}
              _hover={{ bg: 'gray.500' }}
              bg="#274a69"
              color="white"
              icon={<HiOutlineLogout size={20} />}
              onClick={() => handleLogout()}
            >
              Sign out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </HStack>
  );
};

export default UserMenu;
