import React from 'react';
import { Tag, Badge, Box, Table, Tbody, Tr, Td, Flex } from '@chakra-ui/react';
import { B2C_CALCULATOR_SERVICEMODE } from '../../utils/utilityConstants';
import AwbImg from '../Awb/AwbImg';

import {useSelector} from 'react-redux';
import {getB2cConfig} from '../../redux/slices/SettingsDetailsSlice';
function B2cCalculatorPriceCards({ data, ...calcultedPrice }) {
  
  const b2cConfig=useSelector(getB2cConfig);
  const {servicePartners}=b2cConfig;
  const {
    action,
    chargeId,
    codFix,
    codMax,
    serviceType: serviceMode,
    price,
    TAT,
    weight,
    serviceProviderName,
  } = data;
  const {
    deliveryPincode,
    CODamount,
    height,
    length,
    width,
    pickUpPincode,
    paymentMode,
    physicalWeight,
  } = calcultedPrice || {};
  const services = chargeId?.split('-');
  const isGst = services[services?.length - 1] === 'GST';
  const codMaxPrice = (CODamount * codMax) / 100;
  const codCharges =
    paymentMode === 'COD' && codMaxPrice > codFix ? codMaxPrice : codFix;
  const volume = height * length * width;
  const VolumetricWeight = volume / 4700 >= 5 ? volume / 4700 : volume / 5000;
  return (
    <Box w={300} borderWidth="1px" borderRadius="lg" p={2}>
      <Flex flexWrap={'wrap'} justifyContent={'space-around'}>
        <Badge color={paymentMode === 'COD' ? 'teal.500' : 'blue.400'}>
          {paymentMode === 'COD' ? `COD : ${CODamount}` : 'Prepaid'}
        </Badge>
        <Badge colorScheme="teal">
          {
            B2C_CALCULATOR_SERVICEMODE.find(mode => mode?.value === action)
              ?.label
          }
        </Badge>
        <Badge color={isGst ? 'teal.500' : 'blue.400'} textAlign="center">
          {isGst ? 'GST' : 'Non-GST'}
        </Badge>
        <Badge color={'blue.400'}>{serviceMode}</Badge>{' '}
        <AwbImg
          shipmentPartner={serviceProviderName}
          h={14}
          objectFit={serviceProviderName === 'ECOM' ? 'contain' : 'cover'}
          alt="Courier Name"
          loading="lazy"
          icon={servicePartners[serviceProviderName]?.icon}
        />
      </Flex>
      <Table fontSize={14}>
        <Tbody>
          <Tr>
            <Td p={1} whiteSpace={'nowrap'}>
              Physical weight:
            </Td>
            <Td p={1} whiteSpace={'nowrap'}>{`${physicalWeight} kg`}</Td>
          </Tr>
          <Tr>
            <Td p={1} whiteSpace={'nowrap'}>
              Volumetric Weight:
            </Td>
            <Td
              p={1}
              whiteSpace={'nowrap'}
            >{`${height}X${length}X${width} =${VolumetricWeight} kg`}</Td>
          </Tr>
          <Tr>
            <Td p={1} whiteSpace={'nowrap'}>
              Chargeable Weight :
            </Td>
            <Td p={1} whiteSpace={'nowrap'}>
              {weight}
            </Td>
          </Tr>
          <Tr>
            <Td p={1} whiteSpace={'nowrap'}>
              PickUp Pincode :
            </Td>
            <Td p={1} whiteSpace={'nowrap'}>
              {pickUpPincode}
            </Td>
          </Tr>
          <Tr>
            <Td p={1} whiteSpace={'nowrap'}>
              Delivery Pincode:
            </Td>
            <Td p={0}>{deliveryPincode}</Td>
          </Tr>

          {paymentMode === 'COD' && (
            <>
              <Tr>
                <Td p={1} whiteSpace={'nowrap'}>
                  Courier Charges :
                </Td>
                <Td p={0}>{price}</Td>
              </Tr>
              <Tr>
                <Td p={1} whiteSpace={'nowrap'}>
                  Additional COD Charges :
                </Td>
                <Td p={0}> {codCharges}</Td>
              </Tr>
            </>
          )}
          <Tr>
            <Td p={0} color={'blue.400'}>
              Tat:
            </Td>
            <Td p={0} color={'blue.400'}>
              {TAT}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Box fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
        <Tag>
          {paymentMode === 'COD'
            ? (parseFloat(codCharges) + parseFloat(price)).toFixed(2)
            : parseFloat(price)}
        </Tag>
      </Box>
    </Box>
  );
}

export default B2cCalculatorPriceCards;
