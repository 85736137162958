import {
  B2bSidebarOptions,
  B2cSidebarOptions,
  HomeSidebarOptions,
} from './sidebar.options';
import {
  Link,
  Flex,
  Icon,
  Text,
  IconButton,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer,
} from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './sidebar.scss';
import { getIsRouteB2bOrB2c } from '../../redux/slices/ComponentFunctionSlice';
import { useSelector } from 'react-redux';
import { FiMenu } from 'react-icons/fi';
import { COLOR } from '../../utils/constant';
import QuixgoText from '../card/shared/QuixgoText';
const MobileMenu = ({ isHomePage = false }) => {
  const IsRouteB2bOrB2c = useSelector(getIsRouteB2bOrB2c);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const SidebarOptions = isHomePage
    ? HomeSidebarOptions()
    : IsRouteB2bOrB2c === 'b2b'
    ? B2bSidebarOptions()
    : B2cSidebarOptions();
  return (
    <>
      <IconButton
        variant="unstyled"
        aria-label="notifications"
        color="white"
        icon={<Icon as={FiMenu} w={6} h={6} />}
        onClick={onOpen}
        ref={btnRef}
      />
      <Drawer
        finalFocusRef={btnRef}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent bgColor={COLOR.blue}>
          <DrawerCloseButton />
          <DrawerHeader>
            <QuixgoText as="h2" ml={10} fontSize={['3xl']} />
          </DrawerHeader>
          <DrawerBody>
            <Flex className="sidebar-menu" flexDir="column" w="100%" as="nav">
              {SidebarOptions?.map((menuItem, index) => {
                return (
                  <Link
                    key={menuItem.name}
                    as={NavLink}
                    to={menuItem}
                    p={3}
                    ml={5}
                    borderRadius={8}
                    _hover={{
                      textDecor: 'none',
                      backgroundColor: '#274A69',
                      width: '90%',
                    }}
                    onClick={() => onClose()}
                   my={1} 
                  >
                    <Flex
                      key={index}
                      flexDir="column"
                      w="100%"
                      className="sidebar-menu"
                    >
                      <Flex>
                        <Icon
                          as={menuItem.icon}
                          fontSize="xl"
                          color={'white'}
                        />
                        <Text color="white" ml={5}>
                          {menuItem.name}
                        </Text>
                      </Flex>
                    </Flex>
                  </Link>
                );
              })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileMenu;
