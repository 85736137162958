export const CustomerType = {
  NORMAL: 'Normal',
  BUSINESS: 'Business',
  FRANCHISE: 'Franchise',
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
  Flat:'Flat',
  Other: 'Other',
  GST: 'Gst',
};

export const ProductType = [
  {
    value: 'Documents',
    label: 'Documents',
  },
  {
    value: 'Books',
    label: 'Books',
  },
  {
    value: 'Cloths',
    label: 'Cloths',
  },
  {
    value: 'Gifts',
    label: 'Gifts',
  },
  {
    value: 'Electronics',
    label: 'Electronics',
  },
  {
    value: 'Food',
    label: 'Food',
  },
  {
    value: 'Jewelry',
    label: 'Jewelry',
  },
  {
    value: 'Medicines',
    label: 'Medicines',
  },
  {
    value: 'Household',
    label: 'Household',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
export const PackagingType = [
  {
    value: 'Envelope',
    label: 'Envelope',
  },
  {
    value: 'Parcel Bag',
    label: 'Parcel Bag',
  },
  {
    value: 'Parcel Box',
    label: 'Parcel Box',
  },
  {
    value: 'Not Packed',
    label: 'Not Packed',
  },
];
export const ParcelQty = [
  {
    value: '1 Parcel',
    label: '1 Parcel',
  },
  {
    value: '2 Parcel',
    label: '2 Parcel',
  },
  {
    value: '3 Parcel',
    label: '3 Parcel',
  },
  {
    value: '4 Parcel',
    label: '4 Parcel',
  },
  {
    value: '5 Parcel',
    label: '5 Parcel',
  },
  {
    value: '6 Parcel',
    label: '6 Parcel',
  },
  {
    value: '7 Parcel',
    label: '7 Parcel',
  },
  {
    value: '8 Parcel',
    label: '8 Parcel',
  },
  {
    value: '9 Parcel',
    label: '9 Parcel',
  },
  {
    value: '10 Parcel',
    label: '10 Parcel',
  },
  {
    value: 'Bulk Parcel',
    label: 'Bulk Parcel',
  },
];

export const USER_ROLES = {
  AGENT: 'agent',
  NORMAL: 'normal',
  ADMIN: 'admin',
  MANAGER: 'manager',
  SUPPORT: 'support',
  SUPER: 'super',
};

export const MERCHANTS_FILTER = {
  VERIFIED: true,
  UNVERIFIED: false,
  GST: 'gst',
  NORMAL: 'normal',
  INFANT: 'infinite',
  NEW: 'new',
};

export const CREDIT_REASON = {
  cancelled: 'Cancelled',
  recharged: 'Recharged',
  wrongCharged: 'Wrong Charge',
  waiver: 'waiver',
  lost: 'Lost',
  damage: 'Damage',
};
export const NOTES_CATEGORY = {
  lrNum: 'LR Number',
  shipmentId: 'Shipment Id',
  jobId: 'Job Id',
};
export const DEBIT_REASON = {
  fine: 'Fine charged',
  wrongCharged: 'Wrong Charged',
  overWeightCharge: 'Over Weight Adjustment',
};
export const PAYMENT_MODE = {
  debit: 'Debit Card',
  credit: 'Credit Card',
  ebt: 'EBT',
  pCheck: 'P Check',
  eCheck: 'E Check',
  upi: 'UPI',
  qrCode: 'QR Code',
  mobileWallet: 'Mobile Wallet',
};

export const TRANSFER_MODE = {
  CREDIT: 'Credit Note',
  BANK: 'Bank Transfer',
};
export const CHARGED_MODE = {
  Quixgo: 'Quixgo',
  Government: 'Government',
  ShippingPartner: 'Shipping Partner',
};

export const manifestSettingData = [
  {
    name: 'quixgoLogo',
    title: 'Quixgo logo',
    description: 'Hide/Show Quixgo Logo',
  },
  {
    name: 'customerLogo',
    title: 'Display Logo',
    description: 'Hide/Show Display Logo',
  },
  {
    name: 'invoiceValue',
    title: 'Invoice Value',
    description: 'Hide/Show Invoice Value',
  },
  {
    name: 'returnAddress',
    title: 'Return Address',
    description: 'Hide/Show Return Address',
  },
  {
    name: 'sellerContactDetails',
    title: 'Seller Information',
    description: 'Hide/Show Seller Contact Details',
  },
  {
    name: 'products',
    title: 'Products Information',
    description: 'Hide/Show Products Details',
  }
  // ,
  // {
  //   name: 'weight',
  //   title: 'Weight',
  //   description: 'Hide/Show Weight',
  // },
  // {
  //   name: 'consigneeMobile',
  //   title: 'consignee Mobile',
  //   description: 'Hide/Show consignee Mobile',
  // },
];
export const Zone = [
  { label: 'Within City', value: 'city' },
  { label: 'Metro to Metro', value: 'metro' },
  { label: 'Within Zone', value: 'zone' },
  { label: 'Rest of India', value: 'india' },
  { label: 'Extended Location', value: 'special' },
];

export const B2bCalculatorSERVICEMODETYPE = [{ label: 'Forward', value: 'FW' }];

export const B2BSERVICEMODETYPE = [{ label: 'Forward', value: 'FORWARD' }];

export const B2C_SERVICEMODE = [
  { label: 'Forward', value: 'FW' },
  { label: 'Reverse', value: 'RV' },
];
export const B2C_CALCULATOR_SERVICEMODE = [
  ...B2C_SERVICEMODE,
  { label: 'Return', value: 'RT' },
];
