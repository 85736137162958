import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  setPickupLocations,
  setIsPickupEdit,
  setShipmentStatus,
  setIsStatusLoading,
  setPickupLocationLoading,
  setIsBookingDone,
  setIsBooking,
  setIsPincodeDataLoading,
  setIsShipmentStatusSuccess,
  setIsRequestPickupLoading,
  setDeliveryPincodeData,
  setPickupPincodeData,
} from '../slices/BookingDetailsSlice';
import {
  httpCall,
  DOWNLOAD_BOOKING_SAMPLEFILE_URL,
  SINGLE_BOOKING_URL,
  GET_ALL_PICKUP_URL,
  EDIT_PICKUP_FORMDATA_URL,
  GET_AWB_STATUS_URL,
  CANCEL_SHIPMENT_URL,
  CANCEL_SHIPMENT_BULK_URL,
  GET_PINCODE_DATA_URL,
  REQUEST_PICKUP_URL,
  REMOVE_SHIPMENT_URL,
  BULK_BOOKING_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
import download from 'js-file-download';
import { setAddressInfo } from '../slices/CustomerDetailsSlice';
import { getSuccessCount } from '../../utils/utilsFunction';
import { setSelectedShipmentInfo } from '../slices/ShipmentDetailsSlice';
import { fetchAllShipments } from './ShipmentDetailsSaga';
import {
  getCustomerId,
  getFilterFormData,
  getIsRouteB2bOrB2c,
  getLimit,
  getOrder,
  getPage,
  getSortBy,
} from '../slices/ComponentFunctionSlice';
import { getLoggedUser } from '../slices/UserDetailsSlice';
import { getAllPickupDetails } from './PickupPointsDetailsSaga';
export function* fetchPickupLocation(params) {
  let { payload } = params;
  let isB2BorB2c = yield select(getIsRouteB2bOrB2c);
  payload = payload ? payload : params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllPickupUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_PICKUP_URL}/${isB2BorB2c}`;
  yield put(setPickupLocationLoading(true));
  try {
    const pickupLocationDetails = yield call(httpCall, {
      url: getAllPickupUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (pickupLocationDetails?.success) {
      const custPickupAddress = pickupLocationDetails.data.filter(address => {
        return address.addressType !== 'self';
      });

      yield put(setPickupLocations(custPickupAddress));
      const custAddress = pickupLocationDetails.data.filter(
        address => address.addressType === 'self'
      )[0];
      const address2 = custAddress?.address2.split('-');
      const addressParams = {
        addressId: custAddress.addressId,
        address1: custAddress.address1,
        address2: address2[0],
        city: address2[1],
        district: custAddress.city,
        state: custAddress.state,
        pin: custAddress.pincode,
      };
      yield put(setAddressInfo(addressParams));
      yield put(setPickupLocationLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setPickupLocationLoading(false));
  }
}

export function* downloadBookingSampleFile() {
  const setPartnerUrl = `${process.env.REACT_APP_BASEURL}${DOWNLOAD_BOOKING_SAMPLEFILE_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const partnerDetails = yield call(httpCall, {
      url: setPartnerUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (partnerDetails?.success) {
      const data = partnerDetails.data;
      download(data, 'SampleBookingFile.xlsx');
      toast.success(' Sample File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* singleShipmentBooking(params) {
  const pathName = yield select(getIsRouteB2bOrB2c);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);
  const customerId = yield select(getCustomerId);
  const loggedUser = yield select(getLoggedUser);
  const sortBy = yield select(getSortBy);
  const order = yield select(getOrder);
  yield put(setIsBookingDone(true));
  const { postData } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const url = Array.isArray(postData) ? BULK_BOOKING_URL : SINGLE_BOOKING_URL;
  const getBookingUrl = `${process.env.REACT_APP_BASEURL}${url}/${pathName}`;
  try {
    const bookingDetails = yield call(httpCall, {
      url: getBookingUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (bookingDetails?.success) {
      if (Array.isArray(postData)) {
        yield put(setSelectedShipmentInfo([]));
        const count = getSuccessCount(bookingDetails.data);
        toast.success(
          `${count} out of ${bookingDetails.data.length} Shipment Booked Successfully`,
          {
            closeOnClick: true,
            draggable: true,
          }
        );
      } else {
        toast.success('Shipment Booked Successfully', {
          closeOnClick: true,
          draggable: true,
        });
      }

      yield put(setIsBooking(true));
      yield put(setIsBookingDone(false));
      yield fetchAllShipments({
        type: 'GET_ALL_SHIPMENT_LIST',
        payload: {
          empId: loggedUser?.empId,
          limit: limit,
          page: page,
          filter: { customerId, ...filterFormData },
          sortBy,
          order,
        },
      });
    } else {
      toast.error(
        bookingDetails?.err?.data
          ? bookingDetails.err.data
          : 'Something Went wrong',
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBooking(false));
      yield put(setIsBookingDone(false));
    }
  } catch (err) {
    yield put(setIsBookingDone(false));
    console.log(err);
  }
}

export function* editPickupFormdata(payload) {
  let isB2BorB2c = yield select(getIsRouteB2bOrB2c);
  let loggedUser = yield select(getLoggedUser);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const EditPickupDataURL = `${process.env.REACT_APP_BASEURL}${EDIT_PICKUP_FORMDATA_URL}/${isB2BorB2c}`;
  const { postData, customerId } = payload;

  try {
    const GetEdit = yield call(httpCall, {
      url: EditPickupDataURL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (GetEdit?.success) {
      yield put(setIsPickupEdit(GetEdit.data)); //function call //yield call(function name,parameter)
      let allFetchLocationParam = {
        empId: loggedUser.empId,
        limit: 10,
        page: 1,
        filter: {
          customerId: customerId,

          addressCategory: 'pickup',
        },

        sortBy: 'createdAt',
        order: 'desc',
      };
      yield getAllPickupDetails({
        type: 'GET_ALL_PICKUPLIST',
        payload: allFetchLocationParam,
      });
      toast.success(`Update Successfully`, {
        closeOnClick: true,
        draggable: true,
        theme: 'colored',
      });
    }
  } catch (err) {
    toast.error('Something Went Wrong', {
      closeOnClick: true,
      draggable: true,
      theme: 'colored',
    });
  }
}

export function* getAwbStatus(payload) {
  yield put(setIsStatusLoading(true));
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAwbStatusURL = `${process.env.REACT_APP_BASEURL}${GET_AWB_STATUS_URL}/${isB2bOrB2c}`;
  const { postData } = payload;
  try {
    const getStatus = yield call(httpCall, {
      url: getAwbStatusURL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (getStatus?.success) {
      yield put(setShipmentStatus(getStatus?.data));
      yield put(setIsStatusLoading(false));
      yield put(setIsShipmentStatusSuccess(true));
    } else {
      yield put(setShipmentStatus(getStatus?.err?.data));
      yield put(setIsShipmentStatusSuccess(false));
      yield put(setIsStatusLoading(false));
      toast.error(getStatus?.err?.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    yield put(setIsStatusLoading(false));
    console.log(err);
  }
}

export function* cancelShipment(payload) {
  const isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);
  const customerId = yield select(getCustomerId);
  const loggedUser = yield select(getLoggedUser);
  const sortBy = yield select(getSortBy);
  const order = yield select(getOrder);
  yield put(setIsBookingDone(true));
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { postData } = payload;
  const url=Array.isArray(postData)?CANCEL_SHIPMENT_BULK_URL: CANCEL_SHIPMENT_URL;
  const cancelShipmentURL =`${process.env.REACT_APP_BASEURL}${url}/${isB2bOrB2c}`;
  try {
    const cancelResponse = yield call(httpCall, {
      url: cancelShipmentURL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json', //json
        Authorization: token,
      },
      data: postData,
    });
    if (cancelResponse?.success) {
      yield fetchAllShipments({
        type: 'GET_ALL_SHIPMENT_LIST',
        payload: {
          empId: loggedUser?.empId,
          limit: limit,
          page: page,
          filter: { customerId, ...filterFormData },
          sortBy,
          order,
        },
      });
      if (Array.isArray(postData)) {
        yield put(setSelectedShipmentInfo([]));
        const count = getSuccessCount(cancelResponse.data);
        toast.success(
          `${count} out of ${cancelResponse.data.length} Shipment Marked As Canceled Successfully`,
          {
            closeOnClick: true,
            draggable: true,
          }
        );
      } else {
        toast.success(
          postData?.awbNumber
            ? `${postData?.awbNumber} Shipment Marked As Canceled Successfully`
            : 'Shipment Marked As Canceled Successfully',
          {
            closeOnClick: true,
            draggable: true,
          }
        );
      }


      
      yield put(setIsBookingDone(false));
    } else {
      toast.error(
        `Unable to process your request. please try after some time or contact admin`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBookingDone(false));
    }
    yield put(setIsBookingDone(false));
  } catch (err) {
    yield put(setIsStatusLoading(false));
    yield put(setIsBookingDone(false));
    console.log(err);
  }
}
export function* getDeliveryPincodeData(params) {
  yield put(setIsPincodeDataLoading(true));
  const { postData } = params;
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getPincodeDataUrl = `${process.env.REACT_APP_BASEURL}${GET_PINCODE_DATA_URL}/${postData}`;
  try {
    const pincodeDetails = yield call(httpCall, {
      url: getPincodeDataUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (pincodeDetails?.success) {
      yield put(setDeliveryPincodeData(pincodeDetails?.data));
      yield put(setIsPincodeDataLoading(false));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsPincodeDataLoading(false));
    }
  } catch (err) {
    yield put(setIsPincodeDataLoading(true));
    console.log(err);
  }
}
export function* getPickupPincodeData(params) {
  const { postData } = params;
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getPickupPincodeDataUrl = `${process.env.REACT_APP_BASEURL}${GET_PINCODE_DATA_URL}/${postData}`;
  try {
    const PickupPincodeDetails = yield call(httpCall, {
      url: getPickupPincodeDataUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (PickupPincodeDetails?.success) {
      yield put(setPickupPincodeData(PickupPincodeDetails?.data));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* Request_Pickup(params) {
  yield put(setIsRequestPickupLoading(true));
  const { postData } = params;
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const RequestPickupUrl = `${process.env.REACT_APP_BASEURL}${REQUEST_PICKUP_URL}`;
  try {
    const RequestPickupDetails = yield call(httpCall, {
      url: RequestPickupUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (RequestPickupDetails?.success) {
      yield put(setIsRequestPickupLoading(false));
      toast.success(`${RequestPickupDetails?.data}`, {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      yield put(setIsRequestPickupLoading(false));
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* Delete_Shipment(params) {
  yield put(setIsBookingDone(true));
  const { postData, listType } = params;
  let pathName = yield select(getIsRouteB2bOrB2c);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);
  const customerId = yield select(getCustomerId);
  const loggedUser = yield select(getLoggedUser);
  const sortBy = yield select(getSortBy);
  const order = yield select(getOrder);
  const deleteShipmentUrl = `${process.env.REACT_APP_BASEURL}${REMOVE_SHIPMENT_URL}/${pathName}`;
  try {
    const deleteResponse = yield call(httpCall, {
      url: deleteShipmentUrl,
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      data: postData,
    });
    if (deleteResponse?.success) {
      yield put(setSelectedShipmentInfo([]));
      yield put(setIsRequestPickupLoading(false));
      yield fetchAllShipments({
        type: 'GET_ALL_SHIPMENT_LIST',
        payload: {
          empId: loggedUser?.empId,
          limit: limit,
          page: page,
          filter: { customerId, ...filterFormData },
          sortBy,
          order,
        },
      });
      const count = getSuccessCount(deleteResponse.data);
      toast.success(
        `${count} out of ${deleteResponse.data.length} Shipment Deleted Successfully`,
        {
          closeOnClick: true,
          draggable: true,
        }
      );
      yield put(setIsBookingDone(false));
    } else {
      yield put(setIsRequestPickupLoading(false));
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchBookingDetialsSaga() {
  yield takeLatest('GETALL_PICKUP_LOCATION', fetchPickupLocation);
  yield takeLatest('DOWNLOAD_BOOKING_SAMPLE', downloadBookingSampleFile);
  yield takeLatest('SINGLE_SHIPMENT_BOOKING', singleShipmentBooking);
  yield takeLatest('EDIT_PICKUP_FORMDATA', editPickupFormdata);
  yield takeLatest('GET_AWB_STATUS', getAwbStatus);
  yield takeLatest('CANCEL_SHIPMENT', cancelShipment);
  yield takeLatest('GET_DEL_PINCODE_DATA', getDeliveryPincodeData);
  yield takeLatest('GET_PIC_PINCODE_DATA', getPickupPincodeData);
  yield takeLatest('REQUEST_PICKUP', Request_Pickup);
  yield takeLatest('DELETE_SHIPMENT', Delete_Shipment);
}

export default function* BookingDetailsSaga() {
  yield all([watchBookingDetialsSaga()]);
}
