import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  setAllInsuranceList,
  setInsuranceDetailsLoading,
} from '../slices/InsuranceDetailsSlice';
import { httpCall, GET_ALL_INSURANCES_URL } from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
export function* getAllInsutanceDetails(payload) {
  const { postData } = payload;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllInsuranceUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_INSURANCES_URL}`;
  yield put(setInsuranceDetailsLoading(true));
  try {
    const insuranceDetails = yield call(httpCall, {
      url: getAllInsuranceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (insuranceDetails && insuranceDetails.success) {
      yield put(setAllInsuranceList(insuranceDetails.data));

      yield put(setInsuranceDetailsLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setInsuranceDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchInsutanceDetailsSaga() {
  yield takeLatest('GET_ALL_INSURANCE', getAllInsutanceDetails);
}

export default function* InsutanceDetailsSaga() {
  yield all([watchInsutanceDetailsSaga()]);
}
