import React from 'react';
import {
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  HStack,
  Flex,
  Select,
  IconButton,
} from '@chakra-ui/react';
import { MdArrowDropDown, MdDelete } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa';
import { useFieldArray } from 'react-hook-form';

const DimensionInput = ({ register, control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'volume',
  });

  return (
    <>
      <>
        <Flex
          flexDir={'column'}
          gap={4}
          border={'1px solid'}
          borderColor={'blackAlpha.200'}
          p={5}
          rounded={'md'}
        >
          <>
            <FormControl isInvalid={errors.unit}>
              <Select
                w={150}
                {...register('unit', {
                  required: 'This is required',
                })}
                icon={<MdArrowDropDown />}
                textTransform={'capitalize'}
                fontWeight={'bold'}
                color={'#718096'}
              >
                {['cm', 'feet', 'inch'].map(mode => (
                  <option
                    style={{
                      fontWeight: 'bold',
                      color: '#718096',
                    }}
                    key={mode}
                    value={mode}
                  >
                    {mode}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.unit && errors.unit.message}
              </FormErrorMessage>
            </FormControl>
          </>
          {fields?.map((field, index) => (
            <HStack key={field.id}>
              <FormControl isInvalid={errors.volume?.[index]?.count}>
                <Input
                  type="number"
                  placeholder="Qty"
                  _placeholder={{
                    fontWeight: 'bold',
                  }}
                  {...register(`volume.${index}.count`, {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.count?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.volume?.[index]?.height}>
                <Input
                  type="number"
                  placeholder="Height"
                  _placeholder={{
                    fontWeight: 'bold',
                  }}
                  {...register(`volume.${index}.height`, {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.height?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.volume?.[index]?.length}>
                <Input
                  type="number"
                  placeholder="Length"
                  _placeholder={{
                    fontWeight: 'bold',
                  }}
                  {...register(`volume.${index}.length`, {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.length?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.volume?.[index]?.width}>
                <Input
                  type="number"
                  placeholder="Width"
                  _placeholder={{
                    fontWeight: 'bold',
                  }}
                  {...register(`volume.${index}.width`, {
                    required: 'This is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.volume?.[index]?.width?.message}
                </FormErrorMessage>
              </FormControl>
              {fields?.length !== 1 && (
                <IconButton
                  icon={<MdDelete />}
                  onClick={() => remove(index)}
                  colorScheme="red"
                />
              )}
            </HStack>
          ))}
          <Button
            w={150}
            mx={'auto'}
            colorScheme="orange"
            size={'sm'}
            leftIcon={<FaPlus />}
            onClick={() =>
              append({ count: '', height: '', length: '', width: '' })
            }
          >
            Add More
          </Button>
        </Flex>
      </>
    </>
  );
};

export default DimensionInput;
