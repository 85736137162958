import React from 'react';
import { Flex, Stack, Image } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import {
  getPasswordChanges,
  getUserVarifyStatus,
} from '../redux/slices/UserDetailsSlice';
import PasswordResetForm from '../containers/Auth/ResetPasswordForm';
import OtpForm from '../containers/Auth/OtpInput';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const isUserVarified = useSelector(getUserVarifyStatus);
  const isPasswordChanged = useSelector(getPasswordChanges);
  const navigate = useNavigate();
  if (isPasswordChanged) {
    navigate('/login');
    dispatch({ type: 'LOGOUT' });
  }
  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        {isUserVarified ? <PasswordResetForm /> : <OtpForm />}
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
          }
        />
      </Flex>
    </Stack>
  );
};

export default ResetPasswordPage;
