import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input
} from "@chakra-ui/react";

const WidthFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.width}>
      <FormLabel htmlFor="width">
        Width (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="width"
        type="number"
        step={"any"}
        placeholder="Enter Breadth "
        {...register("width", {
          required: "This is required",
        })}
      />

      <FormErrorMessage>{errors?.width?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default WidthFormControl;