import { createSlice } from '@reduxjs/toolkit';
export const DashboardDetailsSlice = createSlice({
  name: 'DashboardDetailsSlice',
  initialState: {
    dashboardData: null,
    isDashboardLoader: false,
    cardCount: null,
    remmitence: {},
    // serviceProviderCount: {},
    locationCount: {},
  },
  reducers: {
    setDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
    setIsDashboardLoader: (state, action) => {
      state.isDashboardLoader = action.payload;
    },
    setCardCount: (state, action) => {
      state.cardCount = action.payload;
    },
    setRemmitence: (state, action) => {
      state.remmitence = action.payload;
    },
    // setServiceProviderCount: (state, action) => {
    //   state.serviceProviderCount = action.payload;
    // },
    setLocationCount: (state, action) => {
      state.locationCount = action.payload;
    },
  },
});
export const {
  setDashboardData,
  setIsDashboardLoader,
  setCardCount,
  setRemmitence,
  // setServiceProviderCount,
  setLocationCount,
} = DashboardDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getDashboardData = state =>
  state.DashboardDetailsSlice.dashboardData;
export const getIsDashboardLoader = state =>
  state.DashboardDetailsSlice.isDashboardLoader;
export const getCardCount = state => state.DashboardDetailsSlice.cardCount;
export const getRemmitence = state => state.DashboardDetailsSlice.remmitence;
// export const getServiceProviderCount = state =>
//   state.DashboardDetailsSlice.serviceProviderCount;
export const getLocationCount = state =>
  state.DashboardDetailsSlice.locationCount;
///we can also write thunks by hand , which may contain both sync and async logic

export default DashboardDetailsSlice.reducer;
