import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Select
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export const ServiceModeControl = ({
  controlId,
  label,
  errors,
  control,
  options,
}) => {
  return (
    <FormControl isInvalid={errors[controlId]}>
      <FormLabel htmlFor={controlId}>
        {label}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Controller
        control={control}
        name="serviceMode"
        rules={{ required: "This is required" }}
        render={({ field }) => (
          <Select placeholder="Select Service Mode" id={controlId} {...field}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      />
      {errors[controlId] && (
        <FormErrorMessage>{errors[controlId].message}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default ServiceModeControl;