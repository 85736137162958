import { createSlice } from '@reduxjs/toolkit';
export const CustomerSettingsSlice = createSlice({
  name: 'CustomerSettingsSlice',
  initialState: {
    customerSetting: '',
    isCustomerSettingLoading: false,
  },
  reducers: {
    setCustomerSetting: (state, action) => {
      state.customerSetting = action.payload;
    },
    setIsCustomerSettingLoading: (state, action) => {
      state.isCustomerSettingLoading = action.payload;
    },
    setManifestSetting: (state, action) => {
      state.manifestSetting = action.payload;
    },
  },
});
export const {
  setCustomerSetting,
  setIsCustomerSettingLoading,
  setManifestSetting,
} = CustomerSettingsSlice.actions;

export const getCustomerSetting = state =>
  state.CustomerSettingsSlice.customerSetting;
export const getIsCustomerSettingLoading = state =>
  state.CustomerSettingsSlice.isCustomerSettingLoading;
export const getManifestSetting = state =>
  state.CustomerSettingsSlice.manifestSetting;
export default CustomerSettingsSlice.reducer;
