import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const EmailForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange' });

  const handleEmailSubmit = () => {
    const values = getValues();
    dispatch({ type: 'SEND_OTP', postData: { email: values.email } });
  };
  return (
    <>
      <form>
        <FormControl isInvalid={errors.email}>
          <FormLabel htmlFor="email">Email Id</FormLabel>
          <Input
            id="email"
            // type='email'
            placeholder="abc@xyz.com"
            {...register('email', {
              pattern: {
                value: /[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                message: 'Wrong email id',
              },
            })}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <Button
          mt={4}
          color={'blue.300'}
          // isLoading={isSubmitting}
          type="button"
          onClick={e => handleEmailSubmit(e)}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default EmailForm;
