import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Text, GridItem, Flex, SimpleGrid } from '@chakra-ui/react';
import { B2bCalculatorSERVICEMODETYPE } from '../../utils/utilityConstants';
import DimensionInput from './DimensionInput';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CustomDeliveryPincodeFormControl,
  CustomPincodeFormControl,
  PaymentModeControl,
  CustomerIdFormControl,
} from './CalculatorField';
import CodAmountFormControl from '../BookShipments/BookingFormField/CodAmountFormControl';
import InvoiceFormControl from '../BookShipments/BookingFormField/InvoiceFormControl';
import ServiceModeControl from '../BookShipments/BookingFormField/ServiceModeControl';
import WeightFormControl from '../BookShipments/BookingFormField/WeightFormControl';
import B2bInsuranceType from "../BookShipments/BookingFormField/B2bInsuranceType";
const B2BcalculatorForm = ({ close }) => {
  const loggedUser = useSelector(getLoggedUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      volume: [{ count: '', height: '', length: '', width: '' }],
    },
  });

  function convertToCm(value, unit) {
    if (unit.toLowerCase() === 'feet') {
      return value * 30.48;
    } else if (unit?.toLowerCase() === 'inch') {
      return value * 2.54;
    }
    return value;
  }

  function onSubmit(values) {
    const { customerId } = values;
    let errorOccurred = false;
    try {
      values?.volume.forEach(entry => {
        entry.height = convertToCm(parseFloat(entry?.height), values?.unit);
        entry.length = convertToCm(parseFloat(entry?.length), values?.unit);
        entry.width = convertToCm(parseFloat(entry?.width), values?.unit);
      });
    } catch (error) {
      errorOccurred = true;
      console.error('Error during form submission:', error);
    } finally {
      if (errorOccurred) {
        console.log('Form submission failed');
      } else {
        const params = {
          ...values,
          empId: loggedUser?.empId,
        };
        dispatch({
          type: 'CALCULATE_PRICE',
          payload: params,
        });

        dispatch({
          type: 'GET_PICKUP_LOCATION',
          payload: { customerId: loggedUser?.customerId },
        });
        navigate('/b2b/calculatePrice', { state: { customerId } });
      }
    }
    close();
  }

  return (
    <Flex color="blackAlpha.800" gap={2} justifyContent={'flex-end'}>
      
    
      <SimpleGrid columns={[1, 1, 1, 2]} spacing={2}>
      <PaymentModeControl
          controlId="paymentMode"
          label="Payment Mode"
          control={control}
          errors={errors}
          isCOD={true}
          isPrepaidAllowed={true}
          isToPayAllowed={true}
          isB2B={true}
        />
         <B2bInsuranceType 
         controlId="customerId"
          label="Customer Id"
         
         errors={errors} control={control} />
        <CustomerIdFormControl
          controlId="customerId"
          label="Customer Id"
          register={register}
          errors={errors}
        />
        
        <ServiceModeControl
          controlId={'serviceMode'}
          label="Service Mode"
          errors={errors}
          control={control}
          options={B2bCalculatorSERVICEMODETYPE}
        />
        <CustomPincodeFormControl
          controlId="pickUpPincode"
          label="Pickup Pincode"
          placeholder="Pickup Pincode"
          register={register}
          errors={errors}
        />
        <CustomDeliveryPincodeFormControl
          controlId="deliveryPincode"
          label="Delivery Pincode"
          placeholder="Delivery Pincode"
          register={register}
          errors={errors}
        />
        {watch('paymentMode') === 'COD' && (
          <CodAmountFormControl register={register} errors={errors} />
        )}

        <InvoiceFormControl
          controlId="invoice"
          label="Invoice Value"
          register={register}
          errors={errors}
        />
        <WeightFormControl
          register={register}
          errors={errors}
          control={control}
        />

        <GridItem colSpan={2}>
          <Text fontSize={'18px'} color={'blackAlpha.700'}>
            Dimensions{' '}
          </Text>

          <DimensionInput
            register={register}
            control={control}
            errors={errors}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              mt={4}
              colorScheme="blue"
              isLoading={isSubmitting}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </GridItem>
      </SimpleGrid>
    </Flex>
  );
};
export default B2BcalculatorForm;
