import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
  setLoggedUserDetails,
  setUserInfoLoader,
  setUserEmailId,
  setUserVarifyLoader,
  setUserVarificationError,
  setUserVarifyStatus,
  setPasswordChanges,
  setEmailLoader,
  setUserList,
  setRefrealCodeList,
} from '../slices/UserDetailsSlice';
import {
  httpCall,
  REGISTER_USER_URL,
  LOGIN_URL,
  SEND_OTP_URL,
  RESEND_OTP_URL,
  VARIFY_OTP_URL,
  UPDATE_PASSWORD_URL,
  GET_ALL_USERS_URL,
  SET_REFERRAL_CODE_URL,
  GET_REFERRAL_CODE_URL,
} from '../../utils/api';
import {
  AUTH_USER_CG_TOKEN_KEY,
  COURIER_SERVICE_LOGIN,
} from '../../utils/constant';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let qs = require('qs');
export function* RegisterUser(payload) {
  const { postDate } = payload;

  const adduserUrl = `${process.env.REACT_APP_BASEURL}${REGISTER_USER_URL}`;

  try {
    const addUserValues = yield call(httpCall, {
      url: adduserUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: postDate,
    });
    if (addUserValues?.success) {
      // yield put(setUserName(addUserValues.data));
      yield call(getAllUsersList);
      toast.success('User Added Successfuly');
    } else {
      toast.error('Something went wrong');
    }
  } catch (err) {
    console.log(err);
  }
}
export function* LoginUser(params) {
  try {
    const { postData } = params;
    yield put(setUserInfoLoader(true));
    let data = qs.stringify(postData);
    const loginUrl = `${process.env.REACT_APP_BASEURL}${LOGIN_URL}`;
    const loggedUserValue = yield call(httpCall, {
      url: loginUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    });
    if (loggedUserValue?.success) {
      const { token } = loggedUserValue.data;
      // if (postData.remember) {
      //   localStorage.setItem(
      //     AUTH_USER_CG_TOKEN_KEY,
      //     `${token}_=${loggedUserValue.data.annotation_id}`
      //   );
      // }

      cookies.set(`${AUTH_USER_CG_TOKEN_KEY}_R`, loggedUserValue.data.refreshToken, { path: '/' });
      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, token);
      delete loggedUserValue.data.refreshToken;

      localStorage.setItem(AUTH_USER_CG_TOKEN_KEY, token);
      localStorage.setItem(
        COURIER_SERVICE_LOGIN,
        JSON.stringify(loggedUserValue.data)
      );
      
      localStorage.setItem('userRole',loggedUserValue.data?.userRole);
      yield put(setLoggedUserDetails(loggedUserValue.data));
      toast.success('User Login Successful');
      yield put(setUserInfoLoader(false));

    } else {
      yield put(setUserInfoLoader(false));
      toast.error(loggedUserValue.err.data);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* sendOTP(params) {
  const { postData } = params;
  const userInfoUrl = `${process.env.REACT_APP_BASEURL}${SEND_OTP_URL}`;
  const data = qs.stringify(postData);
  try {
    const loggedUserValue = yield call(httpCall, {
      url: userInfoUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data,
    });
    if (loggedUserValue?.success) {
      yield put(setUserEmailId(loggedUserValue.data));
      toast.success('Email Sent Successfuly');
    } else {
      toast.error(loggedUserValue.err.data);
      yield put(setUserInfoLoader(false));
    }
  } catch (err) {
    console.log(err);
    toast.error('Something went wrong!');
  }
}
export function* ResendOtp(params) {
  try {
    const { postData } = params;
    console.log(postData);
    let data = qs.stringify(postData);
    const otpUrl = `${process.env.REACT_APP_BASEURL}${RESEND_OTP_URL}`;
    const otpEmailValues = yield call(httpCall, {
      url: otpUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    });
    if (otpEmailValues?.success) {
      yield put(setEmailLoader(false));
      toast.success('Email Sent Successfuly');
    }
  } catch (err) {
    console.log(err);
  }
}

export function* VarifyUserOtp(params) {
  try {
    const { postData } = params;
    console.log(postData);
    let data = qs.stringify(postData);
    const verifyUserUrl = `${process.env.REACT_APP_BASEURL}${VARIFY_OTP_URL}`;
    const otpVarifyValues = yield call(httpCall, {
      url: verifyUserUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: data,
    });
    if (otpVarifyValues?.success && !otpVarifyValues.data.error) {
      yield put(setUserVarifyLoader(false));
      yield put(setUserVarificationError(false));
      yield put(setUserVarifyStatus(true));
    } else {
      yield put(setUserVarificationError(true));
      yield put(setUserVarifyLoader(false));
      toast.error(otpVarifyValues.data.error);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* updateUserPassword(params) {
  try {
    const { postData } = params;
    const verifyUserUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_PASSWORD_URL}`;
    const passwordChnageValues = yield call(httpCall, {
      url: verifyUserUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: postData,
    });
    if (passwordChnageValues?.success && !passwordChnageValues.data.error) {
      yield put(setPasswordChanges(true));
      toast.success('Password Changed Successfuly');
    } else {
      yield put(setPasswordChanges(false));
      toast.error(passwordChnageValues.err.data.error);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getAllUsersList(params) {
  try {
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getUsersUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_USERS_URL}`;
    const userListValues = yield call(httpCall, {
      url: getUsersUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (userListValues?.success && !userListValues.data.error) {
      yield put(setUserList(userListValues.data));
    } else {
      toast.error(userListValues.err.data.error);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addReferalCode(payload) {
  const { postDate } = payload;

  const addReferalUrl = `${process.env.REACT_APP_BASEURL}${SET_REFERRAL_CODE_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const addReferalResponse = yield call(httpCall, {
      url: addReferalUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postDate,
    });
    if (addReferalResponse && addReferalResponse.status === 200) {
      // yield call(getAllUsersList);
      toast.success('Referal Code Created Successfuly');
    } else if (addReferalResponse && addReferalResponse.status === 201) {
      // yield call(getAllUsersList);
      toast.success(
        'The old code is active for this user ' +
          addReferalResponse.data.refralCode
      );
    } else if (addReferalResponse.err.data === 'Validation error') {
      toast.error('Please Enter Unique Referal Code');
    } else {
      toast.error(addReferalResponse.err.data);
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getAllReferalCodes(params) {
  try {
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getAllRefealCodesUrl = `${process.env.REACT_APP_BASEURL}${GET_REFERRAL_CODE_URL}`;
    const referalListResponse = yield call(httpCall, {
      url: getAllRefealCodesUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data: data
    });
    if (referalListResponse?.success) {
      yield put(setRefrealCodeList(referalListResponse.data));
    } else {
      toast.error(referalListResponse.err.data);
    }
  } catch (err) {
    console.log(err);
  }
}
export function* watchUserDetailsSaga() {
  yield takeLatest('REGISTER_USER', RegisterUser);
  yield takeLatest('LOGIN_USER', LoginUser);
  yield takeLatest('SEND_OTP', sendOTP);
  yield takeLatest('RESEND_OTP', ResendOtp);
  yield takeLatest('VERIFY_USER_OTP', VarifyUserOtp);
  yield takeLatest('UPDATE_PASSWORD', updateUserPassword);
  yield takeLatest('GET_ALL_USERS', getAllUsersList);
  yield takeLatest('ADD_REFERRAL_CODE', addReferalCode);
  yield takeLatest('GET_ALL_REFERRAL_CODES', getAllReferalCodes);
}
export default function* UserDetailsSaga() {
  yield all([watchUserDetailsSaga()]);
}
