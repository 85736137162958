import React, { useEffect,useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Login from '../pages/Loginside.page';
import PageNotFound from './PageNotFound.routes';
import RoutesConfig from './config.routes';
import PrivateLayout from '../layouts/private.layouts';
import HomeLayout from '../layouts/Home.layouts';
import Layout from '../layouts/public.layouts';
import { COURIER_SERVICE_LOGIN,AUTH_USER_CG_TOKEN_KEY } from '../utils/constant';
import ResetPasswordPage from '../pages/ResetPassword.page';
import B2bRoutesConfig from './b2b.routes';
import HomeRoutesConfig from './Home.routes'
import {
  setLoggedUserDetails,
  getLoggedUser,
  getUserInfoLoader
} from '../redux/slices/UserDetailsSlice';
import AllMerchantDetails from '../pages/AllMerchantDetails.page';
// import AllMerchantList from '../containers/AllMerchant/AllmerchantList';
// import { Text } from '@chakra-ui/react';

const QuixRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const isDetailsFetching = useSelector(getUserInfoLoader);
  const [isLoading, setIsLoading] = useState(false);
  const loggedUser = useSelector(getLoggedUser);
  useEffect(() => {
    setIsLoading(isDetailsFetching);
  }, [isDetailsFetching]);
  const isLoggedIn = JSON.parse(localStorage.getItem(COURIER_SERVICE_LOGIN));
  useEffect(() => {
    if(!isLoggedIn?.token){
      navigate('/login')
    }
  }, [loggedUser]);
  if (!loggedUser && isLoggedIn?.token) {
    const userDetails = JSON.parse(
      localStorage.getItem(COURIER_SERVICE_LOGIN)
    );
    dispatch(setLoggedUserDetails(userDetails));
  }
  const addPathPrefix = (routes, prefix) =>
    routes.map(route => ({ ...route, path: `${prefix}${route.path}` }));
  
  const prefixedB2bPrivateRoute = addPathPrefix(
    B2bRoutesConfig.privateRoute,
    '/b2b'
  );
  const prefixedB2cPrivateRoute = addPathPrefix(
    RoutesConfig.privateRoute,
    '/b2c'
  );
  return (
    <Routes>
          <Route path="/" element={<HomeLayout />} />
          <Route path="/allMerchant" element={<AllMerchantDetails />}>
          {HomeRoutesConfig?.privateRoute.map(publicRoute => (
              <Route key={publicRoute.path} {...publicRoute} />
            ))}
          </Route>
          <Route path="/b2b" element={<PrivateLayout />}>
            {prefixedB2bPrivateRoute.map(publicRoute => (
              <Route key={publicRoute.path} {...publicRoute} />
            ))}
          </Route>
          <Route path="/b2c" element={<PrivateLayout />}>
            {prefixedB2cPrivateRoute.map(publicRoute => (
              <Route key={publicRoute.path} {...publicRoute} />
            ))}
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="login" element={<Login />} />
            <Route path="resetPassword" element={<ResetPasswordPage />} />
          </Route>
      <Route path="/:pageName" element={<PageNotFound />} />
    </Routes>
  );
};
export default QuixRoutes;
