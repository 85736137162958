import { createSlice } from '@reduxjs/toolkit';
export const ExcelDownloadSlice = createSlice({
  name: 'ExcelDownloadSlice',
  initialState: {
    ExcelData: [],
    isExcelDataLoading: false,
  },
  reducers: {
    setExcelData: (state, action) => {
      state.ExcelData = action.payload;
    },
    setIsExcelDataLoading: (state, action) => {
      state.isExcelDataLoading = action.payload;
    },
  },
});
export const { setExcelData, setIsExcelDataLoading } =
  ExcelDownloadSlice.actions;
export const getExcelData = state => {
  return state.ExcelDownloadSlice.ExcelData;
};
export const getIsExcelDataLoading = state =>
  state.ExcelDownloadSlice.isExcelDataLoading;
export default ExcelDownloadSlice.reducer;
