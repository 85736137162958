import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  setCustomers,
  setIsCustomerInfoLoading,
  setCustomerInfo,
  setMerchantLoader,
  setBankDetails,
  setIsBankingInfoLoading,
  setKycDocs,
  setQuixgoBankDetails,
  setIsKycDocsLoading,
  setManagerList
} from '../slices/MerchantDetailsSlice';
import {
  httpCall,
  GET_CUSTOMERS_LIST_URL,
  GET_CUSTOMER_INFO_URL,
  VERIFY_CUSTOMER_URL,
  UPDATE_BANKING_DETAILS_URL,
  GET_BANKING_DETAILS_URL,
  VERIFY_BANK_DETAILS_URL,
  GET_KYC_DETAILS_URL,
  CUSTOMER_SETTINGS_UPDATE_URL,
  GET_CUSTOMER_MANAGER_LIST_URL,
  GRANT_ACCESS_USER_URL
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
export function* getState() {
  const state = yield select();
  return state.userDetail.userId;
}
export function* fetchAllMerchants(payload) {
  const { postData, CustomerType } = payload;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getCustomerUrl = CustomerType
    ? `${process.env.REACT_APP_BASEURL}${GET_CUSTOMERS_LIST_URL}/${CustomerType}`
    : `${process.env.REACT_APP_BASEURL}${GET_CUSTOMERS_LIST_URL}`;
  yield put(setMerchantLoader(true));
  try {
    const partnerDetails = yield call(httpCall, {
      url: getCustomerUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (partnerDetails && partnerDetails.success) {
      yield put(setCustomers(partnerDetails.data));
      yield put(setMerchantLoader(false));
      // toast.success('User Added Successfuly');
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setMerchantLoader(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setMerchantLoader(false));
  }
}

export function* getCustomerInfo(params) {
  yield put(setIsCustomerInfoLoading(true));
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;

  const getCustomerUrl = `${process.env.REACT_APP_BASEURL}${GET_CUSTOMER_INFO_URL}/${payload.id}`;
  try {
    const customerDetails = yield call(httpCall, {
      url: getCustomerUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      // data: payload,
    });
    if (customerDetails && customerDetails.success) {
      yield put(setCustomerInfo(customerDetails.data));
      yield put(setIsCustomerInfoLoading(false));
    } else {
      toast.error(customerDetails.data || customerDetails.error||'Something went wrong');
      yield put(setIsCustomerInfoLoading(false));
    }
  } catch (err) {
    console.log(err);
    setIsCustomerInfoLoading(false);
  }
}

export function* verifyCustomerDetails(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;
  const verifyDetailsUrl = `${process.env.REACT_APP_BASEURL}${VERIFY_CUSTOMER_URL}`;
  try {
    const updateResponse = yield call(httpCall, {
      url: verifyDetailsUrl,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (updateResponse && updateResponse.success) {
      toast.success(' Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
      const params = {
        id: payload.customerDetails.customerId,
      };
      yield getCustomerInfo({
        type: 'GET_CUSTOMER_INFO',
        payload: params,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
    toast.error('Something went wrong');
  }
}

export function* updateBankingDetails(params) {
  try {
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const updateBankingInfoUrl = `${process.env.REACT_APP_BASEURL}${UPDATE_BANKING_DETAILS_URL}`;
    const formData = new FormData();
    formData.append('bankingInfo', JSON.stringify(postData.bankingInfo));
    if (Array.isArray(postData.cancelCheck)) {
      formData.append(
        'check',
        postData.cancelCheck[0],
        postData.cancelCheck[0].name
      );
    } else {
      formData.append('check', postData.cancelCheck);
    }
    const bankingDetailsResponse = yield call(httpCall, {
      url: updateBankingInfoUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (bankingDetailsResponse && bankingDetailsResponse.success) {
      const params = {
        customerId: postData.bankingInfo.customerId,
      };
      yield getCustomerInfo({
        type: 'GET_CUSTOMER_INFO',
        postData: params,
      });
      toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error(bankingDetailsResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}

export function* getBankingDetails(params) {
  try {
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getBankingInfoUrl = `${process.env.REACT_APP_BASEURL}${GET_BANKING_DETAILS_URL}`;
    yield put(setIsBankingInfoLoading(true));
    const bankingDetailsResponse = yield call(httpCall, {
      url: getBankingInfoUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (bankingDetailsResponse && bankingDetailsResponse.success) {
      // if (postData.customerId === 'quixgo') {
      //   yield put(setQuixgoBankDetails(bankingDetailsResponse.data));
      // } else {
      yield put(setBankDetails(bankingDetailsResponse.data));
      // }

      yield put(setIsBankingInfoLoading(false));
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsBankingInfoLoading(false));
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}
export function* getQuixgoBankingDetails(params) {
  try {
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getBankingInfoUrl = `${process.env.REACT_APP_BASEURL}${GET_BANKING_DETAILS_URL}`;
    const bankingDetailsResponse = yield call(httpCall, {
      url: getBankingInfoUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (bankingDetailsResponse && bankingDetailsResponse.success) {
      if (postData.customerId === 'quixgo') {
        yield put(setQuixgoBankDetails(bankingDetailsResponse.data));
      }
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
  }
}
export function* verifyBankingDetails(params) {
  try {
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getBankingInfoUrl = `${process.env.REACT_APP_BASEURL}${VERIFY_BANK_DETAILS_URL}`;
    yield put(setIsBankingInfoLoading(true));
    const bankingDetailsResponse = yield call(httpCall, {
      url: getBankingInfoUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (bankingDetailsResponse && bankingDetailsResponse.success) {
      yield put(setBankDetails(bankingDetailsResponse.data));
      const params = {
        customerId: postData.customerId,
      };
      yield getBankingDetails({
        type: 'GET_BANKING_DETAILS',
        postData: params,
      });
      toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsBankingInfoLoading(false));
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
    yield put(setIsBankingInfoLoading(false));
  }
}

export function* getKycDocs(params) {
  try {
    yield put(setIsKycDocsLoading(true));
    yield put(setKycDocs({}));
    const { postData } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getKycDocsUrl = `${process.env.REACT_APP_BASEURL}${GET_KYC_DETAILS_URL}`;
    const kycDocsResponse = yield call(httpCall, {
      url: getKycDocsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (kycDocsResponse?.success) {
      const kycDocs = {};
      kycDocsResponse.data.forEach(docs => (kycDocs[docs.docType] = docs));
      yield put(setKycDocs(kycDocs));
      yield put(setIsKycDocsLoading(false));
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
    yield put(setIsKycDocsLoading(false));
  }
}
export function* updateCustomerSettings(params) {
  try {
    const { payload } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const updateSettingUrl = `${process.env.REACT_APP_BASEURL}${CUSTOMER_SETTINGS_UPDATE_URL}`;
    const customerDetailsResponse = yield call(httpCall, {
      url: updateSettingUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (customerDetailsResponse && customerDetailsResponse.success) {
      const params = {
        id: payload.customerId,
      };
      yield getCustomerInfo({
        type: 'GET_CUSTOMER_INFO',
        payload: params,
      });
      toast.success('Information Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
    setIsCustomerInfoLoading(false);
  }
}
export function* getCustomerManagerList(params) {
  try {
    const { payload } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const getManagersUrl = `${process.env.REACT_APP_BASEURL}${GET_CUSTOMER_MANAGER_LIST_URL}`;
    const managerListResponse = yield call(httpCall, {
      url: getManagersUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (managerListResponse && managerListResponse.success) {
      yield put(setManagerList(managerListResponse.data));
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
  }
}

export function* GrantEmpCustomerAccess(params) {
  try {
    const { payload } = params;
    let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    const grantManagersUrl = `${process.env.REACT_APP_BASEURL}${GRANT_ACCESS_USER_URL}`;
    const managerResponse = yield call(httpCall, {
      url: grantManagersUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (managerResponse && managerResponse.success) {
      const params={};
      yield getCustomerInfo({
        type: 'GET_MANAGERS_LIST',
        payload: params,
      });
      toast.success('User Updated Successfully', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something Went Wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchMerchantDetailsSaga() {
  yield takeLatest('GET_ALL_CUSTOMERS', fetchAllMerchants);
  yield takeLatest('GET_CUSTOMER_DETAILS', getCustomerInfo);
  yield takeLatest('VERIFY_CUSTOMER_INFO', verifyCustomerDetails);
  yield takeLatest('UPDATE_BANKING_DETAILS', updateBankingDetails);
  yield takeLatest('GET_BANKING_DETAILS', getBankingDetails);
  yield takeLatest('GET_QUIXGO_BANKING_DETAILS', getQuixgoBankingDetails);
  yield takeLatest('VERIFY_BANKING_INFO', verifyBankingDetails);
  yield takeLatest('GET_KYC_DOCS', getKycDocs);
  yield takeLatest('UPDATE_CUSTOMER_SETTINGS', updateCustomerSettings);
  yield takeLatest('GET_MANAGERS_LIST',getCustomerManagerList);
  yield takeLatest('GRANT_ACCESS_CUSTOMER',GrantEmpCustomerAccess);
}

export default function* MerchantDetailsSaga() {
  yield all([watchMerchantDetailsSaga()]);
}
