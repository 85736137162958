import React, { useRef } from 'react';
// import { useSelector } from 'react-redux';
import { Grid, GridItem, 
  // useDisclosure,
   useMediaQuery } from '@chakra-ui/react';
// import { getLoggedUser } from '../redux/slices/UserDetailsSlice';
import './pages.scss';
import MainNavbar from '../components/card/shared/MainNavbar';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import { COLOR } from '../utils/constant';
import Navbar from '../components/navbar';

const AllMerchantsPage = () => {
  const [isGreterThan768] = useMediaQuery('(min-width: 769px)');
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  // const loggedUser = useSelector(getLoggedUser);
  return (
    <Grid
      h={'100vh'}
      maxW={'100vw'}
      templateRows="repeat(12, 1fr)"
      templateColumns="repeat(23, 1fr)"
    >
      <GridItem bg={COLOR.blue} rowSpan={1} colSpan={23}>
      <Navbar 
      // onOpen={onOpen} 
      btnref={btnRef} isHomePage={true}  />
        {/* <MainNavbar loggedUser={loggedUser} /> */}
      </GridItem>
      {isGreterThan768 && (
        <GridItem bg={COLOR.blue} rowSpan={11} colSpan={1}>
          <Sidebar isHomePage={true} />
        </GridItem>
      )}
      <GridItem
        bg={'whiteAlpha.100'}
        rowSpan={11}
        colSpan={isGreterThan768 ? 22 : 23}
        overflowX="hidden"
        p={5}
      >
        <Outlet />
      </GridItem>
    </Grid>
  );
};

export default AllMerchantsPage;
