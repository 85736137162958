import { sub, format, parse } from 'date-fns';
export function truncate(str, n, useWordBoundary) {
  if (str && str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1);
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(' '))
      : subString) + '...'
  );
}

export function filterCustomer(arr, value, field) {
  const merchantList = [];
  Array.isArray(arr) &&
    arr.forEach(item => {
      if (item[field] === value) {
        merchantList.push(item);
      }
    });
  return merchantList;
}

export function filterCustomerType(arr, userType, field) {
  const merchantList = [];
  let message = 'No Records Available';
  Array.isArray(arr) &&
    arr.forEach(item => {
      if (item[field] === userType) {
        merchantList.push(item);
      }
    });

  if (merchantList.length > 0) {
    return merchantList;
  } else {
    return message;
  }
}

export function SearchInput(arr, input) {
  let message = 'No Records Found';
  const data =
    Array.isArray(arr) &&
    arr.length > 0 &&
    arr.filter(item => {
      if (input == '') {
        return item;
      } else if (
        item?.cpName?.toLowerCase().includes(input?.toLowerCase()) ||
        item?.customerId?.toLowerCase().includes(input?.toLowerCase()) ||
        item?.updatedAt?.toLowerCase().includes(input?.toLowerCase()) ||
        item?.discount?.toLowerCase().includes(input?.toLowerCase()) ||
        item?.walletAmount?.toLowerCase().includes(input?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(input?.toLowerCase()) ||
        item?.cpMobile?.toLowerCase().includes(input?.toLowerCase()) ||
        item?.customerName?.toLowerCase().includes(input?.toLowerCase())
      ) {
        return item;
      }
    });
  if (data.length > 0) return data;
  else return message;
}

// Function for flatten an object and check there is any valid json string

export function isValidJsonString(value) {
  try {
    let obj = JSON.parse(value);
    if (!Array.isArray(obj) && typeof obj === 'object') {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}

export function isValidObject(value) {
  try {
    if (!Array.isArray(value) && typeof value === 'object') {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
function flattenObject(obj, key) {
  let newObj = JSON.parse(obj);
  newObj = Object.keys(newObj).reduce((result, item) => {
    result[`${key}_${item}`] = newObj[item];
    return result;
  }, {});
  return newObj;
}
export function jsonStringToFlattenObject(arr) {
  function processValue(obj, key) {
    if (obj[key]) {
      let isJsonString = isValidJsonString(obj[key]);
      if (isJsonString) {
        let newObj = flattenObject(obj[key], key);
        obj = { ...obj, ...newObj };
        delete obj[key];
      } else if (!isJsonString) {
        Array.isArray(obj[key]) &&
          obj[key].forEach(item => {
            if (
              item?.statusName?.toLowerCase() === 'delivered' ||
              String(item?.comment)?.toLowerCase() === 'return accepted'
            ) {
              obj.deliveryDate = item?.updateDate;
            }
            obj = { ...obj, ...item };
            delete obj[key];
          });
      }
    }
    return obj;
  }

  let data = arr?.map(value => {
    let obj = { ...value };
    for (let key in value) {
      obj = processValue(obj, key);
    }
    return obj;
  });

  return data;
}
export function isJSON(data) {
  if (typeof data !== 'string') {
    return false;
  }
  try {
    JSON.parse(data);
    return true;
  } catch (error) {
    return false;
  }
}
export function objectToFlattenObject(arr) {
  let finalres = arr?.map(value => {
    let final = { ...value };

    for (let key in value) {
      let isObject = isValidObject(value[key]);
      if (isObject) {
        let newObj = value[key];

        newObj =
          newObj != null &&
          Object.keys(newObj).reduce((obj, item) => {
            obj[`${key}_${item}`] = newObj[item];
            return obj;
          }, {});
        final = { ...final, ...newObj };
        delete final[key];
        delete final?.address_otherInfo;
      }
    }
    return final;
  });
  return finalres;
}

export function flattenArray(arr) {
  let flattenJsonObject = jsonStringToFlattenObject(arr);
  let flattenObject = objectToFlattenObject(flattenJsonObject);
  // let result = jsonStringToFlattenObject(flattenObject)
  return flattenObject;
}

export function flattenForInsurance(arr) {
  let data = jsonStringToFlattenObject(arr);

  let finalres = data.map(value => {
    let final = { ...value };
    let insurenceExcel = {};

    for (let key in value) {
      let isObject = isValidObject(value[key]);
      if (isObject) {
        let newObj = value[key];

        newObj =
          newObj != null &&
          Object.keys(newObj).reduce((obj, item) => {
            obj[`${key}_${item}`] = newObj[item];
            return obj;
          }, {});
        final = { ...final, ...newObj };
        delete final[key];
      }
    }
    let insurenceObj = {
      Serial_No: final.shipmentInsurance_insuranceId,
      Policy_No: '',
      'Amount_Declared*(inv_value)': final.productDetails_invoice,
      'Invoice No / Unique Order No.*': final.shipmentId,
      Invoice_Date: final.bookingDate,
      AWB_No: final.awbNumber,
      AWB_Date: final.createdAt,
      AWB_Month: new Date(final.createdAt).getMonth() + 1,
      Consignee_Name: final.deliveryAddress_name,
      'From( Location)': final.address_city,
      'To (Location)': final.deliveryAddress_city,
      Quantity: final.productDetails_quantity,
      Descritption_Of_Cargo: '',
      Remark: '',
      Place_Of_Issue: '',
      Mode_Of_Conveyance: final.serviceTypes,
    };
    insurenceExcel = { ...insurenceExcel, ...insurenceObj };
    return insurenceExcel;
  });
  return finalres;
}

export const dateCalc = {
  hours24: format(
    sub(new Date(), {
      hours: 24,
    }),
    'yyyy-MM-dd'
  ),
  hours48: format(
    sub(new Date(), {
      hours: 48,
    }),
    'yyyy-MM-dd'
  ),
  week: format(
    sub(new Date(), {
      weeks: 1,
    }),
    'yyyy-MM-dd'
  ),
  last30Days: format(
    sub(new Date(), {
      months: 1,
    }),
    'yyyy-MM-dd'
  ),
  last60Days: format(
    sub(new Date(), {
      months: 2,
    }),
    'yyyy-MM-dd'
  ),
  last90Days: format(
    sub(new Date(), {
      months: 3,
    }),
    'yyyy-MM-dd'
  ),
  today: format(sub(new Date(), { days: 0 }), 'yyyy-MM-dd'),
  yesterday: format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd'),
};
export const formatValue = value => {
  const val = value.toString();
  return val.startsWith('.') ? '0' + val : val;
};
export function isFormdataAvailable(availabledata, formdata) {
  return new Promise((resolve, reject) => {
    const { zone, priorityType, serviceType, serviceProvider } = formdata;
    if (availabledata?.[zone] && availabledata?.[zone][priorityType]) {
      const availableEntry = availabledata[zone][priorityType];
      if (
        availableEntry.serviceType === serviceType &&
        availableEntry.courier === serviceProvider
      ) {
        resolve(true);
      } else {
        resolve(false);
      }
    } else {
      resolve(false);
    }
  });
}
export const isEmpty = obj => Object.keys(obj).length === 0;

export function findUpdateDate(history, searchValue) {
  if (!history || !Array.isArray(history)) {
    return null;
  }

  function isSearchMatch(event, searchValue) {
    const eventStatus = String(event?.statusName)?.toLowerCase();
    const eventComment = String(event?.comment)?.toLowerCase();
    if (
      (searchValue === 'Delivered' &&
        (eventStatus?.includes(searchValue.toLowerCase()) ||
          eventComment?.includes('return accepted'))) ||
      (searchValue === 'Picked' &&
        (eventComment?.includes('picked up') ||
          eventStatus?.includes('picked') ||
          eventStatus?.includes('pickdone') ||
          eventStatus?.includes('manifested') ||
          eventStatus?.includes('pickup_complete') ||
          eventStatus?.includes('out_for_pickup')))
    ) {
      return true;
    }
    return false;
  }

  for (const event of history) {
    if (isSearchMatch(event, searchValue)) {
      if (event?.updateDate) {
        return event?.updateDate;
      }
    }
  }

  return null;
}

export const formatDate = inputDate => {
  if (inputDate !== null && inputDate !== undefined) {
    const parsedDate = parse(inputDate, 'yyyy-MM-dd HH:mm:ss', new Date());
    return {
      date: format(parsedDate, 'EEE MMM dd yyyy '),
      time: format(parsedDate, 'hh:mm:ss '),
    };
  } else {
    return null;
  }
};

export function getRowCodAmount(row, pathName) {
  if (row?.codAmount != null && Number(row?.codAmount) > 0) {
    return row?.codAmount;
  } else if (pathName === 'B2B') {
    return row?.productDetails?.cod;
  } else if (isJSON(row?.productDetails)) {
    return JSON.parse(row?.productDetails).cod;
  } else {
    return 0;
  }
}
export function convertToCm(value, unit) {
  if (unit.toLowerCase() === 'feet') {
    return Number(value * 30.48);
  } else if (unit?.toLowerCase() === 'inch') {
    return Number(value * 2.54);
  }
  return Number(value);
}

export const canSeeButNotEdit = rolesList => {
  let role = localStorage.getItem('userRole');
  let isEditable = rolesList.includes(role);
  console.warn('checkAccess--', isEditable);
  return isEditable;
};
export const canNotSee = rolesList => {
  let role = localStorage.getItem('userRole');
  let checkAccess = !rolesList.includes(role);
  return checkAccess;
};

export  function getSuccessCount(response){
  const successCount=response.reduce((sum,res)=>{
    if(res.status===200 || res.statusCode===200){
      sum=sum+1;
    }
    return sum;
  },0)
  return successCount;
}
