import { createSlice } from '@reduxjs/toolkit';
export const InsuranceDetailsSlice = createSlice({
  name: 'InsuranceDetailsSlice',
  initialState: {
    insuranceList: [],
    isInsuranceDetailsLoading: false,
  },
  reducers: {
    
    setAllInsuranceList: (state, action) => {
      state.insuranceList = action.payload;
    },
    setInsuranceDetailsLoading: (state, action) => {
      state.isInsuranceDetailsLoading = action.payload;
    },
  },
});
export const {
  setAllInsuranceList,
  setInsuranceDetailsLoading,
} = InsuranceDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getAllInsuranceList = state =>
  state.InsuranceDetailsSlice.insuranceList;
export const getInsuranceDetailsLoading = state =>
  state.InsuranceDetailsSlice.isInsuranceDetailsLoading;
///we can also write thunks by hand , which may contain both sync and async logic

export default InsuranceDetailsSlice.reducer;
