import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input
} from "@chakra-ui/react";

const HeightFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.height}>
      <FormLabel htmlFor="height">
        Height (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="height"
        type="number"
        step={"any"}
        placeholder="Enter Height "
        {...register("height", {
          required: "This is required",
        })}
      />

      <FormErrorMessage>{errors?.height?.message}</FormErrorMessage>
    </FormControl>
  );
};
export default HeightFormControl;