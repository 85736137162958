import { createSlice } from '@reduxjs/toolkit';
export const MerchantDetailsSlice = createSlice({
  name: 'MerchantDetailsSlice',
  initialState: {
    merchants: [],
    customerInfo: '',
    isCustomerInfoLoading: false,
    editedMerchantData: {},
    isMerchantLoading: false,
    bankDetails: null,
    isBankingInfoLoading: false,
    kycDocs: {},
    isKycDocsLoading: false,
    customerPriceList: [],
    quixgoBankDetails: null,
    managerList:[]
  },
  reducers: {
    setCustomers: (state, action) => {
      state.merchants = action.payload;
    },
    setIsCustomerInfoLoading: (state, action) => {
      state.isCustomerInfoLoading = action.payload;
    },
    setCustomerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    setEditedMerchantData: (state, action) => {
      state.editedMerchantData = action.payload;
    },
    setMerchantLoader: (state, action) => {
      state.isMerchantLoading = action.payload;
    },
    setBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    setIsKycDocsLoading: (state, action) => {
      state.isKycDocsLoading = action.payload;
    },
    setIsBankingInfoLoading: (state, action) => {
      state.isBankingInfoLoading = action.payload;
    },
    setKycDocs: (state, action) => {
      state.kycDocs = action.payload;
    },
    setCustomerPriceList: (state, action) => {
      state.customerPriceList = action.payload;
    },
    setQuixgoBankDetails: (state, action) => {
      state.quixgoBankDetails = action.payload;
    },
    setManagerList:(state, action) => {
      state.managerList = action.payload;
    },
  },
});
export const {
  setCustomers,
  setIsCustomerInfoLoading,
  setCustomerInfo,
  setEditedMerchantData,
  setMerchantLoader,
  setBankDetails,
  setIsBankingInfoLoading,
  setKycDocs,
  setIsKycDocsLoading,
  setCustomerPriceList,
  setQuixgoBankDetails,
  setManagerList
} = MerchantDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getMerchants = state => state.MerchantDetailsSlice.merchants;
export const getCustomerInfo = state => state.MerchantDetailsSlice.customerInfo;
export const getIsCustomerInfoLoading = state =>
  state.MerchantDetailsSlice.isCustomerInfoLoading;
export const getEditedMerchantData = state =>
  state.MerchantDetailsSlice.editedMerchantData;
export const getMerchantLoader = state =>
  state.MerchantDetailsSlice.isMerchantLoading;
export const getBankDetails = state => state.MerchantDetailsSlice.bankDetails;
export const getIsBankingInfoLoading = state =>
  state.MerchantDetailsSlice.isBankingInfoLoading;
export const getKycDocs = state => state.MerchantDetailsSlice.kycDocs;
export const getIsKycDocsLoading = state =>
  state.MerchantDetailsSlice.isKycDocsLoading;
export const getCustomerPriceList = state =>
  state.MerchantDetailsSlice.customerPriceList;
export const getQuixgoBankDetails = state =>
  state.MerchantDetailsSlice.quixgoBankDetails;
export const getManagerList = state =>
    state.MerchantDetailsSlice.managerList;
///we can also write thunks by hand , which may contain both sync and async logic

export default MerchantDetailsSlice.reducer;

