import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VStack, Flex } from '@chakra-ui/react';
import B2bCalculatorPriceCards from '../containers/Calculator/B2bCalculatorPriceCards';
import {
  getCalculatedPrice,
  setCalculatedPrice,
} from '../redux/slices/ShipmentDetailsSlice';
import { FormTag } from '../containers/Calculator/CalculatorField';
import { getIsRouteB2bOrB2c } from '../redux/slices/ComponentFunctionSlice';
import B2cCalculatorPriceCards from '../containers/Calculator/B2cCalculatorPriceCards';
const CalculatePage = ({ ...props }) => {
  const calculatedPrice = useSelector(getCalculatedPrice);
  const pathName = useSelector(getIsRouteB2bOrB2c);
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(setCalculatedPrice(null));
    };
  }, []);
  const renderCalculator = type => {
    const priceList = calculatedPrice?.[type]?.priceList;
    if (priceList?.length > 0 && priceList?.[0] != null) {
      return (
        <>
          <FormTag tagText={type?.toUpperCase()} />
          {pathName === 'B2B' ? (
            <B2bCalculatorPriceCards
              calculatorDetails={calculatedPrice?.[type]}
            />
          ) : (
            <Flex
              flexWrap={'wrap'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={4}
            >
              {priceList?.map((cardData, index) => (
                <B2cCalculatorPriceCards
                  key={index}
                  data={cardData}
                  {...calculatedPrice?.[type]}
                />
              ))}
            </Flex>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <VStack {...props}>
      {['business', 'gst', 'normal', 'user'].map(type =>
        renderCalculator(type)
      )}
    </VStack>
  );
};

export default CalculatePage;
