import React from 'react';
import { Image } from '@chakra-ui/react';
const AwbImg = ({ shipmentPartner,icon, ...props }) => {

  const imagePath =shipmentPartner && `${process.env.REACT_APP_BASEURL}${icon}` || '';
 
  return (
    <Image
      w={160}
      h={10}
      objectFit="cover"
      src={imagePath}
      alt="Courier Name"
      loading="lazy"
      {...props}
    />
  );
};

export default AwbImg;
