import { createSlice } from '@reduxjs/toolkit';
export const EmployeeDetailsSlice = createSlice({
  name: 'EmployeeDetailsSlice',
  initialState: {
    employeeList: [],
  },
  reducers: {
    setEmployeeList: (state, action) => {
      state.employeeList = action.payload;
    },
  },
});
export const { setEmployeeList } = EmployeeDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getEmployeeList = state => state.EmployeeDetailsSlice.employeeList;
///we can also write thunks by hand , which may contain both sync and async logic

export default EmployeeDetailsSlice.reducer;
