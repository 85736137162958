import { takeLatest, all, call, put,select } from 'redux-saga/effects';
import {
  setPickupDeleteRow,
  setAllPickupPointList,
  setIsAddPickupLoading,
  setIsAllPickupLoading,
} from '../slices/PickupPointsDetailsSlice';
import {
  httpCall,
  GET_ALL_PICKUP_ADDRESS_URL,
  GET_CUSTOMER_Id_URL,
  REGISTER_PICKUP_LOCATION_URL,
  HANDLE_DELETE_IN_PICKUPTABLE_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
import { getIsRouteB2bOrB2c } from '../slices/ComponentFunctionSlice';
import { getLoggedUser } from '../slices/UserDetailsSlice';

export function* getAllPickupDetails(params) {
  yield put(setIsAllPickupLoading(true));
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let { payload } = params;

  const getAllPickupUrl = `${process.env.REACT_APP_BASEURL}${ GET_ALL_PICKUP_ADDRESS_URL}/${isB2bOrB2c}`;
  try {
    const pickupAddressResponse = yield call(httpCall, {
      url: getAllPickupUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (pickupAddressResponse?.success) {
      yield put(setAllPickupPointList(pickupAddressResponse.data));
      yield put(setIsAllPickupLoading(false));
    }
  } catch (err) {
    console.log(err);
    yield put(setIsAllPickupLoading(true));
  }
}

export function* addPickupLocation(params) {
  const { payload,customerId } = params;
  let isB2BorB2c= yield select(getIsRouteB2bOrB2c);
  let loggedUser= yield select(getLoggedUser);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const addPickupLocationUrl = `${process.env.REACT_APP_BASEURL}${REGISTER_PICKUP_LOCATION_URL}/${isB2BorB2c}`;
  yield put(setIsAddPickupLoading(true));
  try {
    const customerDetails = yield call(httpCall, {
      url: addPickupLocationUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (customerDetails?.success) {
      // yield call(fetchPickupLocation,{customerId:customerDetails.data.customerId})
      // toast.success(' Pickup location genrated Successfuly', {
      //   closeOnClick: true,
      //   draggable: true,
      // });
      let allFetchLocationParam = {
        empId:loggedUser.empId,
        limit: 10,
        page: 1,
        filter: {
          customerId: customerId,
          
          addressCategory: "pickup",
        },
        
        sortBy:'createdAt',
        order:'desc'
      }
      yield getAllPickupDetails({
        type: 'GET_ALL_PICKUPLIST',
        payload: allFetchLocationParam,
      });
    } else {
      toast.error('Duplicate Entry', {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsAddPickupLoading(false));
    }
  } catch (err) {
    console.log(err);
    toast.error('Something went wrong', {
      closeOnClick: true,
      draggable: true,
    });
  }
}
export function* DeletePickupDataFromTable(payload) {
  let token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let isB2BorB2c= yield select(getIsRouteB2bOrB2c);
  let loggedUser= yield select(getLoggedUser);
  const DeletePickupDataURL = `${process.env.REACT_APP_BASEURL}${HANDLE_DELETE_IN_PICKUPTABLE_URL}/${isB2BorB2c}`;
  const { postData, allFetchLocationParam,customerId } = payload;

  try {
    const Getdelete = yield call(httpCall, {
      url: DeletePickupDataURL,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (Getdelete?.success) {
      toast.success(`Delete Successfully`, {
        closeOnClick: true,
        draggable: true,
        theme: 'colored',
      });
      yield put(setPickupDeleteRow(Getdelete?.data));
      let allFetchLocationParam = {
        empId:loggedUser.empId,
        limit: 10,
        page: 1,
        filter: {
          customerId: customerId,
          
          addressCategory: "pickup",
        },
        
        sortBy:'createdAt',
        order:'desc'
      }
      yield getAllPickupDetails({
        type: 'GET_ALL_PICKUPLIST',
        payload: allFetchLocationParam,
      });
    }
  } catch (err) {
    toast.error(`Something Went Wrong`, {
      closeOnClick: true,
      draggable: true,
      theme: 'colored',
    });
  }
}
export function* watchPickupDetailsSaga() {
  yield takeLatest('GET_ALL_PICKUPLIST', getAllPickupDetails);
  yield takeLatest('ADD_PICKUP_LOCATION', addPickupLocation);
  yield takeLatest('HANDLE_DELETE', DeletePickupDataFromTable);
}

export default function* ShipmentDetailsSaga() {
  yield all([watchPickupDetailsSaga()]);
}
