import { createSlice } from '@reduxjs/toolkit';
export const ComponentFunctionSlice = createSlice({
  name: 'ComponentFunctionSlice',
  initialState: {
    courierEdit: false,
    ManifestPageSize: '_auto',
    SelectedShipmentIds: [],
    layoutType: null,
    filterFormData: {},
    CustomerInfoEdit: false,
    isRouteB2bOrB2c: '',
    DataLimit: 10,
    customerId: undefined,
    sortBy: 'createdAt',
    order: 'desc',
    page: 1,
    limit: 10,
    checkBoxCondition: true,
    isTokenInvalid: false,
  },
  reducers: {
    setCheckBoxCondition: (state, action) => {
      state.checkBoxCondition = action.payload;
    },
    setIsTokenInvalid: (state, action) => {
      state.isTokenInvalid = action.payload;
    },
    setCourierEdit: (state, action) => {
      state.courierEdit = action.payload;
    },
    setIsRouteB2bOrB2c: (state, action) => {
      state.isRouteB2bOrB2c = action.payload;
    },
    setCustomerInfoEdit: (state, action) => {
      state.CustomerInfoEdit = action.payload;
    },
    setManifestPageSize: (state, action) => {
      state.ManifestPageSize = action.payload;
    },
    setSelectedShipmentIds: (state, action) => {
      state.SelectedShipmentIds = action.payload;
    },
    setLayoutType: (state, action) => {
      state.layoutType = action.payload;
    },
    setFilterFormData: (state, action) => {
      state.filterFormData = action.payload;
    },
    setDataLimit: (state, action) => {
      state.DataLimit = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
  },
});
export const {
  setCheckBoxCondition,
  setIsTokenInvalid,
  setCourierEdit,
  setManifestPageSize,
  setSelectedShipmentIds,
  setLayoutType,
  setFilterFormData,
  setCustomerInfoEdit,
  setIsRouteB2bOrB2c,
  setDataLimit,
  setCustomerId,
  setLimit,
  setOrder,
  setPage,
  setSortBy,
} = ComponentFunctionSlice.actions;
export const getCheckBoxCondition = state =>
  state.ComponentFunctionSlice.checkBoxCondition;
export const getCourierEdit = state => state.ComponentFunctionSlice.courierEdit;
export const getCustomerId = state => state.ComponentFunctionSlice.customerId;
export const getCustomerInfoEdit = state =>
  state.ComponentFunctionSlice.CustomerInfoEdit;
export const getSelectedShipmentIds = state =>
  state.ComponentFunctionSlice.SelectedShipmentIds;
export const getManifestPageSize = state =>
  state.ComponentFunctionSlice.ManifestPageSize;
export const getLayoutType = state => state.ComponentFunctionSlice.layoutType;
export const getFilterFormData = state =>
  state.ComponentFunctionSlice.filterFormData;
export const getDataLimit = state => state.ComponentFunctionSlice.DataLimit;
export const getIsRouteB2bOrB2c = state =>
  state.ComponentFunctionSlice.isRouteB2bOrB2c;
export const getSortBy = state => state.ComponentFunctionSlice.sortBy;
export const getOrder = state => state.ComponentFunctionSlice.order;
export const getPage = state => state.ComponentFunctionSlice.page;
export const getLimit = state => state.ComponentFunctionSlice.limit;
export const getIsTokenInvalid = (state) =>
  state.ComponentFunctionSlice.isTokenInvalid;


export default ComponentFunctionSlice.reducer;
