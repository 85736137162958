import { takeLatest, all, call, put } from 'redux-saga/effects';
import { setEmployeeList } from '../slices/EmployeeDetailsSlice';
import {
  httpCall,
  GET_ALL_EMPLOYEE_URL,
  ADD_EMPLOYEE_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';

export function* fetchEmployeeDetails() {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllEmployeeUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_EMPLOYEE_URL}`;
  try {
    const employeeDetails = yield call(httpCall, {
      url: getAllEmployeeUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data:data
    });
    if (employeeDetails && employeeDetails.success) {
      yield put(setEmployeeList(employeeDetails.data));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addNewEmployee(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;
  // console.log(postData);
  // var data = qs.stringify(payload);

  const addEmployeeUrl = `${process.env.REACT_APP_BASEURL}${ADD_EMPLOYEE_URL}`;
  try {
    const addEmployeeResponce = yield call(httpCall, {
      url: addEmployeeUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (addEmployeeResponce && addEmployeeResponce.success) {
      yield call(fetchEmployeeDetails);
      toast.success('Employee Added Successfuly', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchMerchantDetailsSaga() {
  yield takeLatest('GETALL_EMPLOYEE', fetchEmployeeDetails);
  yield takeLatest('ADD_NEW_EMPLOYEE', addNewEmployee);
}

export default function* MerchantDetailsSaga() {
  yield all([watchMerchantDetailsSaga()]);
}
