import { combineReducers } from 'redux';
import Cookies from 'universal-cookie';

import { AUTH_USER_CG_TOKEN_KEY,COURIER_SERVICE_LOGIN } from "../../utils/constant";
import UserDetailsSlice from './UserDetailsSlice';
import PartnerDetailsSlice from './PartnerDetailsSlice';
import MerchantDetailsSlice from './MerchantDetailsSlice';
import SettingsDetailsSlice from './SettingsDetailsSlice';
import EmployeeDetailsSlice from './EmployeeDetailsSlice';
import ShipmentDetailsSlice from './ShipmentDetailsSlice';
import DashboardDetailsSlice from './DashboardDetailsSlice';
import TransactionDetailsSlice from './TransactionDetailsSlice';
import InsuranceDetailsSlice from './InsuranceDetailsSlice';
import weightManagementDetailsSlice from './weightManagementDetailsSlice';
import PickupPointsDetailsSlice from './PickupPointsDetailsSlice';
import BookingDetailsSlice from './BookingDetailsSlice';
import CustomerSettingsSlice from './CustomerSettingSlice';
import ComponentFunctionSlice from './ComponentFunctionSlice';
import CustomerDetailsSlice from './CustomerDetailsSlice';
import ExcelDownloadSlice from './ExcelDownloadSlice';
import LeadsDetailsSlice from './LeadsDetailsSlice';

const cookies = new Cookies();

const appReducer = combineReducers({
  UserDetailsSlice,
  PartnerDetailsSlice,
  MerchantDetailsSlice,
  SettingsDetailsSlice,
  EmployeeDetailsSlice,
  ShipmentDetailsSlice,
  DashboardDetailsSlice,
  TransactionDetailsSlice,
  InsuranceDetailsSlice,
  weightManagementDetailsSlice,
  PickupPointsDetailsSlice,
  BookingDetailsSlice,
  CustomerSettingsSlice,
  ComponentFunctionSlice,
  CustomerDetailsSlice,
  ExcelDownloadSlice,
  LeadsDetailsSlice,
});
const RootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    localStorage.removeItem(COURIER_SERVICE_LOGIN);
    localStorage.removeItem(AUTH_USER_CG_TOKEN_KEY);
    cookies.remove(`${AUTH_USER_CG_TOKEN_KEY}_R`);
    localStorage.removeItem('userRole');
    state = undefined;
    localStorage.removeItem('userRole');
  }
  return appReducer(state, action);
};
export default RootReducer;
