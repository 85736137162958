import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Text,
  } from "@chakra-ui/react";
  import { Controller } from "react-hook-form";
const B2bInsuranceType = ({ errors, control }) => {
    return (
      <FormControl isInvalid={errors.insuranceType}>
        <FormLabel htmlFor="insuranceType">
          Insurance Type
          <Text as="span" color="red">
            *
          </Text>
        </FormLabel>
        <Controller
          name="insuranceType"
          control={control}
          rules={{ required: "This is required" }}
          render={({ field }) => (
        <RadioGroup {...field}
        >
          <Stack direction="row">
            <Radio value="carrier">Carrier Risk</Radio>
            <Radio value="owner">Owner Risk</Radio>
            {/* <Radio value="3">3rd Party Insurance</Radio> */}
          </Stack>
        </RadioGroup>
          )}
          />
        <FormErrorMessage>{errors?.insuranceType?.message}</FormErrorMessage>
      </FormControl>
    );
  };
  export default B2bInsuranceType  ;