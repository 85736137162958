import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
} from '@chakra-ui/react';
import React from 'react';

import { getUserEmailId } from '../../redux/slices/UserDetailsSlice';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

const ResetPasswordForm = () => {
  const userEmailId = useSelector(getUserEmailId);
  const dispatch = useDispatch();
  const {
    register,
    getValues,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onChange' });

  const handlePasswordSubmit = () => {
    const values = getValues();
    const params = {
      password: values.password,
      email: userEmailId.email,
    };
    dispatch({ type: 'UPDATE_PASSWORD', postData: params });
  };
  return (
    <>
      <form>
        <FormControl ml={20} isInvalid={errors.password} w={'40%'}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            type="password"
            // placeholder='abc@xyz.com'
            {...register('password', {
              required: true,
              pattern: {
                value:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                message:
                  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character.',
              },
            })}
          />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.cpassword} mt={5} w={'40%'} ml={20}>
          <FormLabel htmlFor="cpassword">Coinfirm Password</FormLabel>
          <Input
            id="cpassword"
            type="password"
            // placeholder='abc@xyz.com'
            {...register('confirm_password', {
              required: true,
              validate: val => {
                if (watch('password') !== val) {
                  return 'Your passwords do no match';
                }
              },
            })}
          />
          <FormErrorMessage>
            {errors.cpassword && errors.cpassword.message}
          </FormErrorMessage>
        </FormControl>
        <Button
          mt={4}
          ml={20}
          color={'blue.300'}
          // isLoading={isSubmitting}
          type="button"
          onClick={e => handlePasswordSubmit(e)}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </>
  );
};

export default ResetPasswordForm;
