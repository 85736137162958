import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import QuixRoutes from './routes/index.routes';
import ErrorBoundary from "./ErrorBoundary";
import theme from './theme/index.theme';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <BrowserRouter>
          <QuixRoutes />
        </BrowserRouter>
        <ToastContainer />
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
