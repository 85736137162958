import "./font.scss";
import { Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
const QuixgoText = ({ ...props }) => {
  return (
    <Link to={"/"}>
      <Text as="h1" {...props}>
        <Text color={"rgb(236,171,11)"} className="quixgotext" as="span">
          QU
        </Text>
        <Text as="span" className="quixgotext" color={"rgb(255,255,255)"}>
          IX
        </Text>
        <Text as="span" className="quixgotext" color={"rgb(49,231,10)"}>
          GO
        </Text>
      </Text>
    </Link>
  );
};

export default QuixgoText;
