import { createSlice } from '@reduxjs/toolkit';
export const PickupPointsDetailsSlice = createSlice({
  name: 'PickupPointsDetailsSlice',
  initialState: {
    pickupDeleteRow: [],
    allPickupPointList: [],
    isAllPickupLoading: false,
    isAddPickupLoading: false,
    pickupPageSelectedDate: [],
  },
  reducers: {
    setPickupDeleteRow: (state, action) => {
      state.pickupDeleteRow = action.payload;
    },
    setAllPickupPointList: (state, action) => {
      state.allPickupPointList = action.payload;
    },
    setIsAllPickupLoading: (state, action) => {
      state.isAllPickupLoading = action.payload;
    },
    setIsAddPickupLoading: (state, action) => {
      state.isAddPickupLoading = action.payload;
    },
    setPickupPageSelectedDate: (state, action) => {
      state.pickupPageSelectedDate = action.payload;
    },
  },
});

export const {
  setPickupDeleteRow,
  setAllPickupPointList,
  setIsAllPickupLoading,
  setIsAddPickupLoading,
  setPickupPageSelectedDate,
} = PickupPointsDetailsSlice.actions;

export const getAllPickupPointList = state =>
  state.PickupPointsDetailsSlice.allPickupPointList;
export const getAllpickupDeleteRow = state =>
  state.PickupPointsDetailsSlice.pickupDeleteRow;
export const getIsAllPickupLoading = state =>
  state.PickupPointsDetailsSlice.isAllPickupLoading;
export const getIsAddPickupLoading = state =>
  state.PickupPointsDetailsSlice.isAddPickupLoading;
export const getPickupPageSelectedDate = state =>
  state.PickupPointsDetailsSlice.pickupPageSelectedDate;

///we can also write thunks by hand , which may contain both sync and async logic

export default PickupPointsDetailsSlice.reducer;
