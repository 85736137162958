import React from 'react';
import QuixgoText from './QuixgoText';
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  VStack,
  Text,
  MenuItem,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { COLOR } from '../../../utils/constant';

const MainNavbar = ({ loggedUser, ...props }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    navigate('/login');
  };
  return (
    <Flex
      alignItems="center"
      justifyContent={'space-between'}
      bg={COLOR.blue}
      h={14}
      px={8}
      {...props}
    >
      <QuixgoText fontSize={['lg', '3xl']} />
      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  name={`${loggedUser.firstName} ${loggedUser.lastName}`}
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  color="white"
                  ml="2"
                >
                  <Text
                    fontSize="sm"
                    color="white"
                  >{`${loggedUser.firstName} ${loggedUser.lastName}`}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg={COLOR.blue} borderColor={COLOR.blue}>
              <MenuDivider />
              <MenuItem onClick={() => handleLogout()}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default MainNavbar;
