import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  setMerchantTransactionList,
  setAllTransactionList,
  setTransactionDetailsLoading,
  setRemittancePaidList,
  setIsInvoiceLoading,
} from '../slices/TransactionDetailsSlice';
import {
  httpCall,
  GET_MERCHANT_TRANSACTIONS_URL,
  GET_ALL_TRANSACTIONS_URL,
  PAY_REMITTANCE_URL,
  GET_ALL_REMITTANCE_URL,
  ADD_ALL_NOTES_URL,
  GET_INVOICE_URL,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
import download from 'js-file-download';
import { getIsRouteB2bOrB2c } from '../slices/ComponentFunctionSlice';
import { fetchAllShipments } from './ShipmentDetailsSaga';
export function* getAllTransactionDetails(payload) {
  const { postData } = payload;
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let TRANSACTION = `${GET_ALL_TRANSACTIONS_URL}/${isB2bOrB2c}`;
  const getAllTransactionUrl = `${process.env.REACT_APP_BASEURL}${
    postData.customerId ? GET_MERCHANT_TRANSACTIONS_URL : TRANSACTION
  }`;
  yield put(setTransactionDetailsLoading(true));
  try {
    const transactionDetails = yield call(httpCall, {
      url: getAllTransactionUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (transactionDetails?.success) {
      yield put(setAllTransactionList(transactionDetails.data));

      yield put(setTransactionDetailsLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setTransactionDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getMerchantTransactionDetails(payload) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { postData } = payload;
  const getAllTransactionUrl = `${process.env.REACT_APP_BASEURL}${GET_MERCHANT_TRANSACTIONS_URL}`;
  yield put(setTransactionDetailsLoading(true));
  try {
    const transactionDetails = yield call(httpCall, {
      url: getAllTransactionUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (transactionDetails?.success) {
      yield put(setMerchantTransactionList(transactionDetails.data));
      yield put(setTransactionDetailsLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setTransactionDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* payRemittance(payload) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  const { postData, dates } = payload;
  const { customerId, empId } = postData;
  const payRemittanceUrl = `${process.env.REACT_APP_BASEURL}${PAY_REMITTANCE_URL}/${isB2bOrB2c}`;
  yield put(setTransactionDetailsLoading(true));
  try {
    const payResponse = yield call(httpCall, {
      url: payRemittanceUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: postData,
    });
    if (payResponse?.success) {
      // yield put(setMerchantTransactionList(transactionDetails.data));
      // yield put(setTransactionDetailsLoading(false));
      toast.success('data saved successfully');

      const postData = {
        empId: empId,
        limit: 10,
        page: 1,
        filter: {
          isSettled: 'false',
          paymentMode: 'cod',
          currentStatus: 'Delivered',
          customerId,
        },
        sortBy: 'createdAt',
        order: 'desc',
      };
      yield fetchAllShipments({
        type: 'GET_ALL_SHIPMENT_LIST',
        payload: postData,
      });
    } else {
      toast.error('Something went wrong');
      yield put(setTransactionDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getRemittanceTransactions(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let { payload } = params;
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  const getAllRemittanceUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_REMITTANCE_URL}/${isB2bOrB2c}`;
  yield put(setTransactionDetailsLoading(true));
  try {
    const getAllRemittanceResponse = yield call(httpCall, {
      url: getAllRemittanceUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (getAllRemittanceResponse?.success) {
      yield put(setRemittancePaidList(getAllRemittanceResponse.data));
      yield put(setTransactionDetailsLoading(false));
    } else {
      toast.error(getAllRemittanceResponse.data);
      yield put(setTransactionDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* payNotes(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let { payload } = params;
  const getAllRemittanceUrl = `${process.env.REACT_APP_BASEURL}${ADD_ALL_NOTES_URL}/${pathName}`;
  // yield put(setTransactionDetailsLoading(true));
  try {
    const getNotesResponse = yield call(httpCall, {
      url: getAllRemittanceUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (getNotesResponse?.success) {
      toast.success(getNotesResponse.data);
      // yield put(setTransactionDetailsLoading(false));
    } else {
      toast.error(getNotesResponse.err.data);
      // yield put(setTransactionDetailsLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* downloadInvoiceFile(payload) {
  const { postData } = payload;
  const pathName = yield select(getIsRouteB2bOrB2c);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const downloadInvoiceFileUrl = `${process.env.REACT_APP_BASEURL}${GET_INVOICE_URL}/${pathName}?customerId=${postData?.customerId}&startDate=${postData?.date}`;
  yield put(setIsInvoiceLoading(true));
  try {
    const invoiceDownloadResponse = yield call(httpCall, {
      url: downloadInvoiceFileUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (invoiceDownloadResponse?.success) {
      const data = invoiceDownloadResponse.data;
      download(data, 'invoice-file.zip');
      toast.success(' invoice File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });

      yield put(setIsInvoiceLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsInvoiceLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* watchTransactionDetailsSaga() {
  yield takeLatest('GET_ALL_TRANSACTIONS', getAllTransactionDetails);
  yield takeLatest('PAY_NOTES', payNotes);
  yield takeLatest(
    'GET_MERCHANTS_Transactions_LIST',
    getMerchantTransactionDetails
  );
  yield takeLatest('PAY_REMITTANCE', payRemittance);
  yield takeLatest('GET_REMITTANCE_TRANSACTION', getRemittanceTransactions);
  yield takeLatest('GET_INVOICE', downloadInvoiceFile);
}

export default function* TransactionDetailsSaga() {
  yield all([watchTransactionDetailsSaga()]);
}
