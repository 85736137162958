import axios from 'axios';
import { AUTH_USER_CG_TOKEN_KEY } from "./constant";
import store from "../redux/store";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

axios.interceptors.request.use(function (config) {
  if(!config.url.includes('/refresh')){
    const token = localStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `${token}`;
    }
  }
  return config;
});
axios.interceptors.response.use(

  response => response,
  async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const refreshToken=cookies.get(`${AUTH_USER_CG_TOKEN_KEY}_R`);
          const refreshTokenRes = await axios(
            {
              url: `${process.env.REACT_APP_BASEURL}/refresh/user`,
              method: "GET",
              headers: { Authorization: `${refreshToken}` },
              withCredentials: true,
              mode: "no-cors",
            }
          );
        localStorage.setItem(AUTH_USER_CG_TOKEN_KEY,refreshTokenRes.data);
          prevRequest.headers['Authorization'] = `Bearer ${refreshTokenRes.data}`;
          return httpCall(prevRequest);
      }else if(error?.response?.status === 403
         && error?.response?.data?.message === "Token Expired"){
        store.dispatch(
          { type: "LOGOUT" }
      );}
      return Promise.reject(error);
  }
);
export const httpCall = async config => {
  let headerConfig;
  if (config?.headers) {
    headerConfig = {
      ...config,
      headers: {
        ...config.headers,
        'Cache-Control': 'no-store,no-cache,',
        Pragma: 'no-cache',
      },
    };
  } else {
    headerConfig = {
      ...config,
      headers: {
        'Cache-Control': 'no-store,no-cache,',
        Pragma: 'no-cache',
      },
    };
  }
  try {
    const response = await axios(headerConfig);
    return {
      success: true,
      data: response.data,
      ...response,
    };
  } catch (err) {
    console.log(err);
    return { success: false, err: err.response };
    // }
  }
};

///// User apis---------------------
export const REGISTER_USER_URL = '/users/registerUser';
export const LOGIN_URL = '/users/login';
export const SEND_OTP_URL = '/users/sendotp';
export const RESEND_OTP_URL = '/users/resendotp';
export const VARIFY_OTP_URL = '/users/verifyOtp';
export const UPDATE_PASSWORD_URL = '/users/changePassword';
export const GET_ALL_USERS_URL = '/users/getAllUser';
export const Add_SHIPPING_PARTNER_URL =
  '/v1/courierProvider/registerServiceProvider';
export const GET_SHIPPING_PARTNERS_URL =
  '/v1/courierProvider/getAllServiceProvider';
export const GET_CUSTOMERS_LIST_URL = '/v1/customers/getAllCustomer';
export const GET_CUSTOMER_INFO_URL = '/v1/customers/getCustomerInfo';
export const UPLOAD_SERVICEABILITY_URL = '/v1/pincodeCoverage/uploadPincodes';
export const VERIFY_CUSTOMER_URL = '/V1/customers/verifyDetails';
export const CUSTOMER_SETTINGS_UPDATE_URL = '/V1/customers/settings/update';
export const DOWNLOAD_SERVICEABILITY_SAMPLE_URL =
  '/V1/download/sample/pincodeCoverage';
export const UPLOAD_PRICE_URL = '/V1/serviceCharge/uploadPrice';
export const DOWNLOAD_PRICE_SAMPLE_URL = '/V1/download/sample/price';
export const UPLOAD_CUSTOMER_PRICE_URL = '/V1/serviceCharge/customer/uploadFile';
export const GET_PRICE_URL = '/V1/serviceCharge/customer/price';
export const CALCULATE_PRICE_URL = '/V1/serviceCharge/admin/calculateCharge';

export const ADD_OFFERCODE_URL = '/V1/OfferCodes/registerOfferCode/';
export const GET_ALL_OFFERCODE_URL = '/V1/OfferCodes/getOfferCodes/';
export const ADD_OFFICE_LOCATION = '/V1/officeLocation/registerOfficeLocation';
export const GET_ALL_OFFICE_LOCATION_DETAILS_URL =
  '/V1/officeLocation/getAllOfficeLocation';
export const ADD_DEPARTMENT_DETAILS_URL = '/V1/department/registerDepartment';
export const GET_ALL_DEPARTMENT_DETAILS_URL = '/V1/department/getAllDepartment';

export const GET_ALL_DESIGNATION_DETAILS_URL = '/V1/designation/getAllDesignation';
export const ADD_DESIGNATION_DETAILS = '/V1/designation/registerDesignation';

export const GET_ALL_IDTYPE_DETAILS_URL = '/V1/identityType/getAllIdentityType';
export const ADD_IDTYPE_DETAILS = '/V1/identityType/registerIdentityType';

export const GET_ALL_EMPLOYEE_URL = '/V1/employee/getAllEmployee';
export const ADD_EMPLOYEE_URL = '/V1/employee/addEmployee';

export const SET_REFERRAL_CODE_URL = '/V1/referralCode/addReferralCode';
export const GET_REFERRAL_CODE_URL = '/V1/referralCode/getAllReferralCode';

////SHIPMENT URL
export const GET_ALL_SHIPMENT_LIST = '/V1/shipment/getAllShipmentInfo';
export const GET_USER__SHIPMENT_LIST_URL =
  '/V1/shipment/allShipmentStatusByCustomer';
export const GET_AWB_STATUS_URL = '/V1/shipmentStatus/statusTrack';
export const CANCEL_SHIPMENT_URL = '/V1/shipment/cancelBooking';
export const CANCEL_SHIPMENT_BULK_URL = "/v2/shipments/cancel";
export const GET_USER_PICKUP_LIST_URL = '/V1/address/getByCustomerId';
export const GET_PICKUP_ADDRESS_URL = '/V1/address/getPickupAddInfo';
export const GET_ADMIN_DASHBOARD_DATA_URL = '/V1/dashboard/admin';
export const GET_USER_DASHBOARD_DATA_URL = '/V1/dashboard/customer';

export const GET_MERCHANT_TRANSACTIONS_URL =
  '/V1/transaction/allTransactionStatusByCustomer';
export const GET_ALL_TRANSACTIONS_URL = '/V1/transaction/getAllTransactionDetails';
export const REMOVE_SHIPMENT_URL = "/v1/shipment/removeShipmentInfo";

export const UPDATE_BANKING_DETAILS_URL =
  '/V1/customerBankDetails/updateBankDetails';
export const GET_BANKING_DETAILS_URL = '/V1/customerBankDetails/getBankDetails';
export const VERIFY_BANK_DETAILS_URL = '/V1/customerBankDetails/verifyBankDetails';
export const GET_KYC_DETAILS_URL = '/V1/customers/getCustomerDocs';

// Insura
export const GET_ALL_INSURANCES_URL = '/V1/shipmentStatus/insurance/all';
export const GET_WEIGHT_DISCREPANCY_URL = '/V1/download/sample/discrepancy';
export const UPLOAD_WEIGHT_DISCREPANCY_URL = '/V1/discrepancy/upload';
export const GET_ALL_WEIGHT_DISCREPANCY_URL = '/V1/discrepancy/shipments';

export const PAY_REMITTANCE_URL = '/V1/pay/remittance';
export const GET_ALL_REMITTANCE_URL = '/V1/pay/getAllTransaction/admin';
export const ADD_ALL_NOTES_URL = '/V1/pay/notes';

export const GET_DUE_REMITTANCE_URL = '/V1/shipmentStatus/remittanceDue';

export const GET_ALL_PICKUP_ADDRESS_URL = '/V1/address/getAllPickupAdd';
export const HANDLE_DELETE_IN_PICKUPTABLE_URL = '/V1/address/deletePickupAdd';
export const GET_CUSTOMER_Id_URL = '/V1/address/getByCustomerId';
export const REGISTER_PICKUP_LOCATION_URL = '/V1/address/registerPickupAdd';

export const EDIT_PICKUP_FORMDATA_URL = '/V1/address/updatePickupAdd';
export const GET_ALL_PICKUP_URL = '/V1/address/getByCustomerId';
export const DOWNLOAD_BOOKING_SAMPLEFILE_URL =
  '/V1/shipment/downloadBookingSampleFile';
export const UPLOAD_BOOKING_URL = '/V1/shipment/uplaodBookingFile';
export const BULK_BOOKING_URL='/v2/shipments/manifest';
export const SINGLE_BOOKING_URL = '/V1/shipment/singleShipmentBooking';
export const GET_ALL_SHIPMENT_URL = '/V1/shipment/allShipmentStatusByCustomer';
export const ADD_KYC_DETAILS_URL = '/V1/customers/kyc';
export const UPDATE_KYC_DETAILS_URL = '/V1/customers/updateDocuments';
export const GET_ALL_IDENTITY_URL = '/V1/identityType/getAllIdentityType';
export const UPDATE_CUSTOMER_INFO_URL = '/V1/customers/updateCustomer';
export const UPDATE_CUSTOMER_SETTING_URL = '/V1/customers/settings/update';

export const UPDATE_CUSTOMER_ADDRESS_URL = '/V1/address/updatePickupAdd';
export const GET_INVOICE_URL = '/V1/download/invoice';
export const GET_EXCEL_DATA_URL = '/V1/download/files';

export const SAVE_DEFAULT_ADDRESS_URL = '/V1/settings/address';
export const SAVE_DEFAULT_BANKACCOUNT_URL = '/V1/settings/default/bankAccount';
export const SAVE_COURIER_SETTINGS_URL = '/V1/settings/courier';
export const SAVE_PACKAGE_VOLUME_URL = '/V1/settings/package';
export const GET_COURIER_SETTINGS_URL = '/V1/settings';
export const SAVE_MANIFEST_SETTING_URL = '/V1/settings/manifestSetting';
export const GET_PINCODE_DATA_URL = '/V1/pincodeCoverage/getPincodeInfo';
export const REQUEST_PICKUP_URL = "/shipment/requestPickup/B2B";
export const GET_EXCEL_URL='/V1/download/files/admin';
export const GET_ALL_LEADS_LIST='/V1/leads/getAllLead';
export const SAVE_LEADS_LIST='/V1/leads/uploadFile';
export const DOWNLOAD_LEADS_SAMPLE_FILE = '/V1/download/sample/lead'
export const EDIT_LEADS_FORM = '/V1/leads/edit';
export const GET_APP_CONFIG_B2C_URL='/V1/config/setting/adminB2C';
export const GET_APP_CONFIG_B2B_URL='/V1/config/setting/adminB2B';
export const GET_CUSTOMER_MANAGER_LIST_URL='/V1/relationManager/getAllRelationShipManager';
export const GRANT_ACCESS_USER_URL='/V1/relationManager/registerRelationShipManager';

export const DOWNLOAD_STATUS_SAMPLE_URL='/V1/download/sample/status';
export const DOWNLOAD_PAYMENTMODE_SAMPLE_URL='/V1/download/sample/paymentMode';
export const STATUS_UPDATE_URL='/V1/shipmentStatus/statusUpdate';
export const UPLOAD_STATUS_URL='/V1/shipmentStatus/UploadFile';
