import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  setAllLeadsList,
  setIsLeadsListLoading,
  setIsFileUploaded,
  setEditFormData,
  setCommentLeadsListData,
  setAllLeadsListComment,
  setIsLeadsListCommentLoading,
} from '../slices/LeadsDetailsSlice';
import {
  httpCall,
  GET_ALL_LEADS_LIST,
  SAVE_LEADS_LIST,
  DOWNLOAD_LEADS_SAMPLE_FILE,
  EDIT_LEADS_FORM,
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
import download from 'js-file-download';
import { getLoggedUser } from '../slices/UserDetailsSlice';
import {
  getFilterFormData,
  getLimit,
  getOrder,
  getPage,
  getSortBy,
} from '../slices/ComponentFunctionSlice';

export function* getAllLeadsDetails(params) {
  const { payload } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllInsuranceUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_LEADS_LIST}`;
  yield put(setIsLeadsListLoading(true));
  try {
    const leadsAllData = yield call(httpCall, {
      url: getAllInsuranceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (leadsAllData && leadsAllData.success) {
      yield put(setAllLeadsList(leadsAllData.data));

      yield put(setIsLeadsListLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsLeadsListLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* getAllLeadsComment(params) {
  const { payload } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllInsuranceUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_LEADS_LIST}`;
  yield put(setIsLeadsListCommentLoading(true));
  try {
    const leadsAllData = yield call(httpCall, {
      url: getAllInsuranceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (leadsAllData && leadsAllData.success) {
      yield put(setAllLeadsListComment(leadsAllData.data));

      yield put(setIsLeadsListCommentLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsLeadsListCommentLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* uploadLeadsFile(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let loggedUser = yield select(getLoggedUser);

  const { payload } = params;

  const formData = new FormData();
  formData.append('file', payload?.file);
  formData.append('fileName', payload?.file?.name);
  const setLeadsUrl = `${process.env.REACT_APP_BASEURL}${SAVE_LEADS_LIST}`;
  try {
    const leadsUploadFile = yield call(httpCall, {
      url: setLeadsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (leadsUploadFile && leadsUploadFile.success) {
      if (
        leadsUploadFile?.data &&
        leadsUploadFile?.data?.newRecord.length > 0
      ) {
        let params = {
          empId: loggedUser?.empId,
          limit: 10,
          page: 1,
          filter: {},
          sortBy: 'createdAt',
          order: 'desc',
        };
        yield getAllLeadsDetails({
          type: 'GET_ALL_LEADS',
          payload: params,
        });
        toast.success('Leads Added Successfully', {
          closeOnClick: true,
          draggable: true,
        });
      } else if (
        leadsUploadFile?.data &&
        leadsUploadFile?.data?.duplicateFound.length > 0
      ) {
        toast.error('Duplicate Entry', {
          closeOnClick: true,
          draggable: true,
        });
      }
      yield put(setIsFileUploaded(true));
    } else {
      toast.error(leadsUploadFile.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setIsFileUploaded(true));
    }
  } catch (err) {
    yield put(setIsFileUploaded(true));

    console.log(err);
  }
}

export function* downloadLeadsSampleFile() {
  const setLeadsUrl = `${process.env.REACT_APP_BASEURL}${DOWNLOAD_LEADS_SAMPLE_FILE}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const formData = new FormData();
  try {
    const leadsDetails = yield call(httpCall, {
      url: setLeadsUrl,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (leadsDetails?.success) {
      const data = leadsDetails.data;
      download(data, 'LeadsSampleFile.xlsx');
      toast.success(' Sample File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* editLeadsFormDetails(params) {
  let loggedUser = yield select(getLoggedUser);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);
  const sortBy = yield select(getSortBy);
  const order = yield select(getOrder);
  const { payload } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllInsuranceUrl = `${process.env.REACT_APP_BASEURL}${EDIT_LEADS_FORM}`;
  yield put(setIsLeadsListLoading(true));
  try {
    const editFormDataRes = yield call(httpCall, {
      url: getAllInsuranceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (editFormDataRes && editFormDataRes.success) {
      yield put(setEditFormData(editFormDataRes.data));
      let params = {
        empId: loggedUser?.empId,
        limit,
        page,
        filter: filterFormData,
        sortBy,
        order,
      };
      yield getAllLeadsDetails({
        type: 'GET_ALL_LEADS',
        payload: params,
      });
      yield put(setIsLeadsListLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsLeadsListLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* editLeadsCommentDetails(params) {
  let loggedUser = yield select(getLoggedUser);
  const limit = yield select(getLimit);
  const page = yield select(getPage);
  const filterFormData = yield select(getFilterFormData);
  const sortBy = yield select(getSortBy);
  const order = yield select(getOrder);
  const { payload,isFile,leadId } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllInsuranceUrl = `${process.env.REACT_APP_BASEURL}${EDIT_LEADS_FORM}`;
  yield put(setIsLeadsListCommentLoading(true));
  try {
    const editFormDataRes = yield call(httpCall, {
      url: getAllInsuranceUrl,
      method: 'post',
      headers: {
        'Content-Type':'application/json',
        // isFile? "multipart/form-data" :'application/json'
      
        Authorization: token,
      },
      data: isFile? JSON.stringify(payload) : payload,
    });
    if (editFormDataRes && editFormDataRes.success) {
      let params = {
        empId: loggedUser?.empId,
        limit,
        page,
        filter: {...filterFormData,leadId:leadId},
        sortBy,
        order,
      };
      yield getAllLeadsComment({
        type: 'GET_ALL_LEADS_COMMENT',
        payload: params,
      });
      yield put(setIsLeadsListCommentLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsLeadsListCommentLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCommentAllLeadsDetails(params) {
  const { payload } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getAllInsuranceUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_LEADS_LIST}`;
  yield put(setIsLeadsListLoading(true));
  try {
    const leadsAllData = yield call(httpCall, {
      url: getAllInsuranceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (leadsAllData && leadsAllData.success) {
      yield put(setCommentLeadsListData(leadsAllData.data));

      yield put(setIsLeadsListLoading(false));
    } else {
      toast.error('Something went wrong');
      yield put(setIsLeadsListLoading(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchLeadsDetailsSaga() {
  yield takeLatest('GET_ALL_LEADS', getAllLeadsDetails);
  yield takeLatest('GET_ALL_LEADS_COMMENT', getAllLeadsComment);
  yield takeLatest('UPLOAD_LEADS_FILE', uploadLeadsFile);
  yield takeLatest('DOWNLOAD_LEADS_SAMPLE_FILE', downloadLeadsSampleFile);
  yield takeLatest('EDIT_LEADS_FORM_DETAILS', editLeadsFormDetails);
  yield takeLatest('EDIT_LEADS_COMMENT_DETAILS', editLeadsCommentDetails);
  yield takeLatest('GET_COMMENT_ALL_LEADS', getCommentAllLeadsDetails);
}

export default function* LeadsDetailsSaga() {
  yield all([watchLeadsDetailsSaga()]);
}
