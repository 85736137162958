import React, { useState, useEffect, useRef } from 'react';
import { InputGroup, InputLeftElement, Icon, Input } from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIsRouteB2bOrB2c } from '../../redux/slices/ComponentFunctionSlice';
import { getIsShipmentStatusSuccess } from '../../redux/slices/BookingDetailsSlice';
import useDebounce from '../../utils/Debaunce';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
const NavbarSearch = ({ ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const pathName = useSelector(getIsRouteB2bOrB2c);
  const isAwbStatus = useSelector(getIsShipmentStatusSuccess);
  const debouncedAwbNumberValue = useDebounce(inputValue, 1000);
  const loggedUser = useSelector(getLoggedUser);
  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setInputValue('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (pathName === 'B2B' && debouncedAwbNumberValue?.length > 5) {
      const fieldNameValue = debouncedAwbNumberValue?.includes('-')
        ? 'jobId'
        : 'lrNum';
      dispatch({
        type: 'GET_AWB_STATUS',
        postData: {
          empId: loggedUser.empId,
          fieldName: fieldNameValue,
          value: debouncedAwbNumberValue,
        },
      });
    } else if (pathName === 'B2C' && debouncedAwbNumberValue?.length > 7) {
      dispatch({
        type: 'GET_AWB_STATUS',
        postData: { awbNumber: debouncedAwbNumberValue },
      });
    }
  }, [debouncedAwbNumberValue]);
  useEffect(() => {
    if (isAwbStatus) {
      const trimmedAwbNumber = debouncedAwbNumberValue?.trim();
      if (trimmedAwbNumber?.length > 7) {
        navigate(`/${pathName?.toLowerCase()}/status/${trimmedAwbNumber}`, {
          replace: true,
        });
      }
    }
  }, [isAwbStatus, debouncedAwbNumberValue]);
  const handleAwbChange = e => {
    setInputValue(e.target.value);
  };
  return (
    <InputGroup {...props}>
      <InputLeftElement pointerEvents="none">
        <Icon as={AiOutlineSearch} />
      </InputLeftElement>
      <Input
        ref={inputRef}
        value={inputValue}
        type="text"
        placeholder={pathName === 'B2B' ? ' Search Lr Num...' : 'Search AWB...'}
        bg="white"
        onChange={handleAwbChange}
        {...props}
      />
    </InputGroup>
  );
};

export default NavbarSearch;
