import { Flex, HStack, useMediaQuery } from '@chakra-ui/react';
import React from 'react';
import { getLoggedUser } from '../../redux/slices/UserDetailsSlice';
import Calculatedrawer from '../../containers/Calculator/CalculatorDrawer';
import { useSelector } from 'react-redux';
import QuixgoText from '../card/shared/QuixgoText';
import MobileMenu from '../sidebar/MobileMenu';
import NavbarSearch from './NavbarSearch';
import UserMenu from './UserMenu';
import { getLayoutType } from '../../redux/slices/ComponentFunctionSlice';
export default function Navbar({ isHomePage }) {
  const loggedUser = useSelector(getLoggedUser);
  const layoutType = useSelector(getLayoutType);
  const [isLessThan540] = useMediaQuery('(max-width: 540px)');
  const [isLessThan768] = useMediaQuery('(max-width: 769px)');
  return (
    <>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        w={'100%'}
        px={[0, 5]}
      >
        <Flex alignItems={'center'}>
          {isLessThan768 && <MobileMenu isHomePage={isHomePage} />}{' '}
          <QuixgoText fontSize={['3xl']} />
        </Flex>
        <HStack spacing={4}>
          {!isLessThan540 && layoutType !== 'allMerchant' && <NavbarSearch />}
          {layoutType !== 'allMerchant' && <Calculatedrawer />}
          <UserMenu loggedUser={loggedUser} />
        </HStack>
      </Flex>
      {isLessThan540 && <NavbarSearch w={'full'} roundedBottom={'none'} />}
    </>
  );
}
