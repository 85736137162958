import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
  setAllShipmentList,
  setShipmentLoader,
  setSelectedPickupAddress,
  setCalculatedPrice,
  setRemittanceDueList,
} from '../slices/ShipmentDetailsSlice';
import {
  httpCall,
  GET_ALL_SHIPMENT_LIST,
  GET_PICKUP_ADDRESS_URL,
  CALCULATE_PRICE_URL,
  GET_DUE_REMITTANCE_URL,
  UPLOAD_STATUS_URL,
  STATUS_UPDATE_URL,
  DOWNLOAD_PAYMENTMODE_SAMPLE_URL,
  DOWNLOAD_STATUS_SAMPLE_URL
} from '../../utils/api';

import download from 'js-file-download';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';
import { getIsRouteB2bOrB2c } from '../slices/ComponentFunctionSlice';

export function* calculatePrice(params) {
  let pathName = yield select(getIsRouteB2bOrB2c);
  const { payload } = params;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getPriceUrl = `${process.env.REACT_APP_BASEURL}${CALCULATE_PRICE_URL}/${pathName}`;
  try {
    const priceDetails = yield call(httpCall, {
      url: getPriceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (priceDetails?.success) {
      yield put(setCalculatedPrice(priceDetails?.data));
    } else {
      yield put(setCalculatedPrice([]));
      toast.error(
        'Either pincode is not covered or something went wrong please contact to admin',
        {
          closeOnClick: true,
          draggable: true,
        }
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAllShipments(params) {
  yield put(setShipmentLoader(true));
  let pathName = yield select(getIsRouteB2bOrB2c);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let { payload } = params;
  const allShipmentsUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_SHIPMENT_LIST}/${pathName}`;
  try {
    const shipmentsResponse = yield call(httpCall, {
      url: allShipmentsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (shipmentsResponse?.success) {
      yield put(setAllShipmentList(shipmentsResponse?.data));
      yield put(setShipmentLoader(false));
    }
  } catch (err) {
    yield put(setShipmentLoader(false));
    console.log(err);
  }
}

export function* getSinglePickupDetails(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let { postData } = params;
  const getAllPickupUrl = `${process.env.REACT_APP_BASEURL}${GET_PICKUP_ADDRESS_URL}/${postData.pickupId}`;
  try {
    const pickupAddressResponse = yield call(httpCall, {
      url: getAllPickupUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });
    if (pickupAddressResponse?.success) {
      yield put(setSelectedPickupAddress(pickupAddressResponse?.data));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* fetchRemittanceDueList(params) {
  yield put(setShipmentLoader(true));
  yield put(setRemittanceDueList([]));
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  let { payload } = params;
  const getDueRemittanceUrl = `${process.env.REACT_APP_BASEURL}${GET_DUE_REMITTANCE_URL}/${isB2bOrB2c}`;
  try {
    const dueRemittanceResponse = yield call(httpCall, {
      url: getDueRemittanceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (dueRemittanceResponse?.success) {
      yield put(setRemittanceDueList(dueRemittanceResponse?.data));
      yield put(setShipmentLoader(false));
      toast.success(`Remittance Due List Fetched Successfully`, {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      yield put(setShipmentLoader(false));
      toast.error(`Something went wrong`, {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    yield put(setShipmentLoader(false));
    console.log(err);
  }
}

export function* updateShipmentStatus(params) {
  yield put(setShipmentLoader(true));
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  let { payload } = params;
  const getDueRemittanceUrl = `${process.env.REACT_APP_BASEURL}${STATUS_UPDATE_URL}/${isB2bOrB2c}`;
  try {
    const dueRemittanceResponse = yield call(httpCall, {
      url: getDueRemittanceUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (dueRemittanceResponse?.success) {
      toast.success(`Status Updated Successfully`, {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error(`Something went wrong`, {
        closeOnClick: true,
        draggable: true,
      });
    }
    yield put(setShipmentLoader(false));
  } catch (err) {
    yield put(setShipmentLoader(false));
    console.log(err);
  }
} 

export function* downloadStatusSampleFile() {
  
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  const setLeadsUrl = `${process.env.REACT_APP_BASEURL}${DOWNLOAD_STATUS_SAMPLE_URL}/${isB2bOrB2c}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  // const formData = new FormData();
  try {
    const leadsDetails = yield call(httpCall, {
      url: setLeadsUrl,
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      responseType: 'blob',
    });
    if (leadsDetails?.success) {
      const data = leadsDetails.data;
      download(data, 'StatusSampleFile.xlsx');
      toast.success(' Sample File Downloaded', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function* uploadStatusFile(params) {
  let isB2bOrB2c = yield select(getIsRouteB2bOrB2c);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);

  const { payload } = params;

  const formData = new FormData();
  formData.append('file', payload?.file);
  formData.append('fileName', payload?.file?.name);
  const setLeadsUrl = `${process.env.REACT_APP_BASEURL}${UPLOAD_STATUS_URL}/${isB2bOrB2c}`;
  try {
    const leadsUploadFile = yield call(httpCall, {
      url: setLeadsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token,
      },
      data: formData,
    });
    if (leadsUploadFile && leadsUploadFile.success) {
     
        toast.success('Status Updated Successfully', {
          closeOnClick: true,
          draggable: true,
        });
      } 
      
    else {
      toast.error(leadsUploadFile.err.data, {
        closeOnClick: true,
        draggable: true,
      });
    }
    // yield put(setIsFileUploaded(true));
  } catch (err) {
    // yield put(setIsFileUploaded(true));
    console.log(err);
  }
}

export function* watchShipmentDetailsSaga() {
  yield takeLatest('CALCULATE_PRICE', calculatePrice);
  yield takeLatest('GET_ALL_SHIPMENT_LIST', fetchAllShipments);
  yield takeLatest('GET_PICKUP_ADDRESS', getSinglePickupDetails);
  yield takeLatest('GET_DUE_REMITTANCE_LIST', fetchRemittanceDueList);
  yield takeLatest('UPDATE_SHIPMENT_STATUS',updateShipmentStatus);
  yield takeLatest('DOWNLOAD_SAMPLE_STATUS_FILE',downloadStatusSampleFile);
  yield takeLatest('UPLOAD_STATUS_FILE',uploadStatusFile);
}

export default function* ShipmentDetailsSaga() {
  yield all([watchShipmentDetailsSaga()]);
}
