import { useMediaQuery, Grid, GridItem } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Navbar from '../components/navbar';
import Sidebar from '../components/sidebar';
import { COLOR,COURIER_SERVICE_LOGIN,AUTH_USER_CG_TOKEN_KEY } from '../utils/constant';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { setIsRouteB2bOrB2c,setLayoutType } from '../redux/slices/ComponentFunctionSlice';
import {getB2bConfig,getB2cConfig,getConfigLoading} from '../redux/slices/SettingsDetailsSlice';
const PrivateLayout = () => {
  const [isGreterThan768] = useMediaQuery('(min-width: 769px)');
  const b2bConfig=useSelector(getB2bConfig);
  const b2cConfig=useSelector(getB2cConfig);
  const isConfigLoading=useSelector(getConfigLoading)
  const dispatch = useDispatch();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const router = useLocation();
  useEffect(() => {
    let pathName = router?.pathname.split('/')[1].toUpperCase();
    dispatch(setIsRouteB2bOrB2c(pathName));
    if (!auth) {
      // cookies.remove(`${AUTH_USER_CG_TOKEN_KEY}_R`)
      navigate('/login');
    }
    if(pathName==='B2B' && Object.keys(b2bConfig).length===0 &&!isConfigLoading){
      dispatch(setLayoutType('b2b'));
    dispatch({ type: "GET_APP_CONFIG", postData:{shipmentType:'B2B'}  });
    dispatch(setIsRouteB2bOrB2c('B2B'));
    }
    if(pathName==='B2C' && Object.keys(b2cConfig).length===0 &&!isConfigLoading){
      dispatch(setLayoutType('b2c'));
    dispatch({ type: "GET_APP_CONFIG", postData:{shipmentType:'B2C'}  })
    dispatch(setIsRouteB2bOrB2c('B2C'));
    }
  }, []);
  return (
    <Grid
      h={'100vh'}
      maxW={'100vw'}
      templateRows="repeat(12, 1fr)"
      templateColumns="repeat(23, 1fr)"
    >
      <GridItem bg={COLOR.blue} rowSpan={1} colSpan={23}>
        <Navbar />
      </GridItem>
      {isGreterThan768 && (
        <GridItem bg={COLOR.blue} rowSpan={11} colSpan={1}>
          <Sidebar />
        </GridItem>
      )}
      <GridItem
        bg={'whiteAlpha.100'}
        rowSpan={11}
        colSpan={isGreterThan768 ? 22 : 23}
        overflowX="hidden"
        p={5}
      >
        <Outlet />
      </GridItem>
    </Grid>
  );
};

export default PrivateLayout;
