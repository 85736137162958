import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input
} from "@chakra-ui/react";

export const InvoiceFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.invoice}>
      <FormLabel htmlFor="invoice">
        Invoice Value
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        id="invoice"
        control={control}
        type="text"
        placeholder="Enter Invoice Value"
        {...register("invoice", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.invoice?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default InvoiceFormControl;