import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Icon,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import B2CcalculatorForm from './B2CcalculatorForm';
import B2BcalculatorForm from './B2BcalculatorForm';
import { BsFillCalculatorFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { getIsRouteB2bOrB2c } from '../../redux/slices/ComponentFunctionSlice';
const Calculatedrawer = () => {
  const IsRouteB2bOrB2c = useSelector(getIsRouteB2bOrB2c);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Icon
        as={BsFillCalculatorFill}
        w={[6, 7]}
        h={[6, 7]}
        color="white"
        _hover={{ cursor: 'pointer' }}
        mr={1}
        onClick={onOpen}
      />
      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        size={IsRouteB2bOrB2c === 'B2B' ? 'lg' : 'full'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerCloseButton />
          <DrawerHeader bg="lightgrey">
            <Heading as="h1" size="md" color={'blackAlpha.700'}>
              Calculator
            </Heading>
          </DrawerHeader>
          <DrawerBody>
            {IsRouteB2bOrB2c === 'B2B' ? (
              <B2BcalculatorForm close={onClose} />
            ) : (
              <B2CcalculatorForm close={onClose} />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default Calculatedrawer;
