import { createSlice } from '@reduxjs/toolkit';
export const BookingDetailsSlice = createSlice({
  name: 'BookingDetailsSlice',
  initialState: {
    PickupLocations: [],
    calCulatedPrice: null,
    pickupDeleteRow: [],
    selectedPickupAddress: null,
    isPickupEdit: false,
    shipmentStatus: null,
    isStatusLoading: true,
    isPickupLocationLoading: false,
    isBooking: false,
    isBookingDone: false,
    PincodeData: null,
    coverage: null,
    isShipmentStatusSuccess: false,
    isRequestPickupLoading: false,
    DeliveryPincodeData: null,
    PickupPincodeData: null,
    isPincodeDataLoading: false,
  },
  reducers: {
    setPickupLocations: (state, action) => {
      state.PickupLocations = action.payload;
    },

    setPickupDeleteRow: (state, action) => {
      state.pickupDeleteRow = action.payload;
    },
    setSelectedPickupAddress: (state, action) => {
      state.selectedPickupAddress = action.payload;
    },
    setIsPickupEdit: (state, action) => {
      state.selectedIsPickupEdit = action.payload;
    },
    setIsShipmentStatusSuccess: (state, action) => {
      state.isShipmentStatusSuccess = action.payload;
    },
    setShipmentStatus: (state, action) => {
      state.shipmentStatus = action.payload;
    },
    setIsStatusLoading: (state, action) => {
      state.isStatusLoading = action.payload;
    },
    setPickupLocationLoading: (state, action) => {
      state.isPickupLocationLoading = action.payload;
    },
    setPincodeData: (state, action) => {
      state.PincodeData = action.payload;
    },
    setDeliveryPincodeData: (state, action) => {
      state.DeliveryPincodeData = action.payload;
    },
    setPickupPincodeData: (state, action) => {
      state.PickupPincodeData = action.payload;
    },
    setIsPincodeDataLoading: (state, action) => {
      state.isPincodeDataLoading = action.payload;
    },
    setCoverage: (state, action) => {
      state.coverage = action.payload;
    },
    setIsBooking: (state, action) => {
      state.isBooking = action.payload;
    },
    setIsBookingDone: (state, action) => {
      state.isBookingDone = action.payload;
    },
    setIsRequestPickupLoading: (state, action) => {
      state.isRequestPickupLoading = action.payload;
    },
  },
});

export const {
  setPickupLocations,
  setPickupDeleteRow,
  setSelectedPickupAddress,
  setIsPickupEdit,
  setIsShipmentStatusSuccess,
  setShipmentStatus,
  setIsStatusLoading,
  setPickupLocationLoading,
  setPincodeData,
  setDeliveryPincodeData,
  setPickupPincodeData,
  setIsPincodeDataLoading,
  setCoverage,
  setIsBooking,
  setIsBookingDone,
  setIsRequestPickupLoading,
} = BookingDetailsSlice.actions;
export const getPickupLocations = state =>
  state.BookingDetailsSlice.PickupLocations;
export const getCalculatedPrice = state =>
  state.BookingDetailsSlice.calCulatedPrice;
export const getAllpickupDeleteRow = state =>
  state.BookingDetailsSlice.pickupDeleteRow;
export const getSelectedPickupAddress = state =>
  state.BookingDetailsSlice.selectedPickupAddress;
export const getIspickupEdit = state => state.BookingDetailsSlice.isPickupEdit;
export const getIsShipmentStatusSuccess = state =>
  state.BookingDetailsSlice.isShipmentStatusSuccess;
export const getShipmentStatus = state =>
  state.BookingDetailsSlice.shipmentStatus;
export const getIsStatusLoading = state =>
  state.BookingDetailsSlice.isStatusLoading;
export const getPickupLocationLoading = state =>
  state.BookingDetailsSlice.isPickupLocationLoading;
export const getBookingStatus = state => state.BookingDetailsSlice.isBooking;
export const getIsBookingDone = state =>
  state.BookingDetailsSlice.isBookingDone;
export const getPincodeData = state => state.BookingDetailsSlice.PincodeData;
export const getDeliveryPincodeData = state =>
  state.BookingDetailsSlice.DeliveryPincodeData;
export const getPickupPincodeData = state =>
  state.BookingDetailsSlice.PickupPincodeData;
export const getPincodeDataLoading = state =>
  state.BookingDetailsSlice.isPincodeDataLoading;
export const getCoverage = state => state.BookingDetailsSlice.coverage;
export const getIsRequestPickupLoading = state =>
  state.BookingDetailsSlice.isRequestPickupLoading;
///we can also write thunks by hand , which may contain both sync and async logic

export default BookingDetailsSlice.reducer;
