import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input
} from "@chakra-ui/react";

const WeightFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.weight}>
      <FormLabel htmlFor="Weight">
        Weight (kg)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="weight"
        type="number"
        step="any"
        placeholder="Weight (0.4 kg) = 400gm"
        {...register("weight", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.weight?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default WeightFormControl;