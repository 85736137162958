import {
  PinInput,
  PinInputField,
  Spinner,
  HStack,
  Text,
  VStack,
  Flex,
  Heading,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { MdEmail } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUserVarifyLoader,
  setEmailLoader,
  getLoggedUser,
  getUserVarifyLoader,
  getUserEmailId,
} from '../../redux/slices/UserDetailsSlice';
import EmailForm from './EmailForm';
const OtpForm = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(getLoggedUser);
  const userVarifyLoader = useSelector(getUserVarifyLoader);
  const userEmailId = useSelector(getUserEmailId);
  const handleOtpVarification = value => {
    const otpParams = {
      otp: value,
      email: userEmailId.email,
    };
    dispatch({ type: 'VERIFY_USER_OTP', postData: otpParams });
    dispatch(setUserVarifyLoader(true));
  };
  const handleResendOtp = () => {
    const userParams = userEmailId;
    dispatch(setEmailLoader(false));
    dispatch({ type: 'RESEND_OTP', postData: userParams });
  };
  return (
    <VStack
      my={4}
      rounded="lg"
      boxShadow="lg"
      px={5}
      py={10}
      spacing={12}
      justifyContent="center"
    >
      <Flex
        bg="white"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        {userVarifyLoader ? (
          <Spinner />
        ) : !userEmailId ? (
          <EmailForm />
        ) : (
          <>
            <Heading as="h3" color="#002060" size="lg" display={'flex'}>
              <Text m={1}>
                <MdEmail color="#002060" />
              </Text>
              Check Your email
            </Heading>
            <Text fontWeight="400" fontSize="md" mb={4} textAlign="center">
              Enter the verification code sent on your email.
            </Text>
            <HStack w="100%" justifyContent="center">
              <PinInput onComplete={handleOtpVarification}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
            <Text>
              Did not receive the email ?
              <Link color={'blue.300'} onClick={() => handleResendOtp()}>
                Click here to resend it.
              </Link>
            </Text>
          </>
        )}
      </Flex>
    </VStack>
  );
};

export default OtpForm;
