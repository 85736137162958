import React,{useEffect} from 'react';
import useAuth from '../hooks/useAuth';
import { Flex, Text, Box, Heading, SimpleGrid } from '@chakra-ui/react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './layout.scss';
import { getLoggedUser } from '../redux/slices/UserDetailsSlice';

import { COLOR,COURIER_SERVICE_LOGIN,AUTH_USER_CG_TOKEN_KEY } from '../utils/constant';
import {
  setIsRouteB2bOrB2c,
  setLayoutType,
} from '../redux/slices/ComponentFunctionSlice';
import QuixgoText from '../components/card/shared/QuixgoText';
import MainNavbar from '../components/card/shared/MainNavbar';


import Cookies from 'universal-cookie';
const cookies = new Cookies();

const LayoutCard = ({ heading, text, onClick, ...props }) => {
  return (
      <Flex
        w={[220]}
        h={[280, 320]}
        bg={COLOR.blue}
        rounded={'xl'}
        color={'white'}
        justifyContent={'center'}
        flexDir={'column'}
        p={[5]}
        gap={2}
        _hover={{ backgroundColor: 'black' }}
        onClick={onClick}
        {...props}
      >
        <Heading color={'whiteAlpha.900'} size="xl" fontWeight={'bold'}>
          {heading}
        </Heading>
        <Text color={'whiteAlpha.700'} lineHeight={1.5}>
          {text}
        </Text>
      </Flex>
  );
};

const Layout = () => {
  const dispatch = useDispatch();
  const loggedUser = useSelector(getLoggedUser);
  const { auth } = useAuth();
  const navigate = useNavigate();
  const router = useLocation();

  useEffect(() => {
    let pathName = router?.pathname.split('/')[1].toUpperCase();
    dispatch(setIsRouteB2bOrB2c(pathName));
    if (!auth || Object.keys(auth).length===0) {
      navigate('/login');
    }
  }, [loggedUser,auth]);

  const handleB2bCardCLick = () => {
    dispatch(setLayoutType('b2b'));
    dispatch({ type: "GET_APP_CONFIG", postData:{shipmentType:'B2B'}  });
    dispatch(setIsRouteB2bOrB2c('B2B'));
  };
  const handleB2cCardCLick = () => {
    dispatch(setLayoutType('b2c'));
    dispatch({ type: "GET_APP_CONFIG", postData:{shipmentType:'B2C'}  })
    dispatch(setIsRouteB2bOrB2c('B2C'));
  };

  const handleAllMerchantCardCLick = () => {
    dispatch(setLayoutType('allMerchant'));
    dispatch(setIsRouteB2bOrB2c('allmerchant'));

  };
 
  return (
    <Box
      backgroundImage={'/web_poster.webp'}
      backgroundSize={'contain'}
      backgroundRepeat={'repeat'}
    >
      <MainNavbar loggedUser={loggedUser} />
      <Flex
        height={['full', 'full', 'full', `calc(100vh - 56px)`]}
        alignItems={'center'}
        w={'100vw'}
        justifyContent={'center'}
      >
        <Flex
          justifyContent={'space-around'}
          alignItems={'center'}
          height={['full', 'full', 'full', `calc(100vh - 156px)`]}
          rounded={'16px'}
          pos={'relative'}
          mt={10}
          p={[0, 5]}
          className="glassUI"
          w={'90%'}
          flexDir={'column'}
          gap={5}
        >
          <>
            <Heading color={'whiteAlpha.900'} fontSize={'xl'} mx={'auto'}>
              Welcome {`${loggedUser.firstName} ${loggedUser.lastName}`}
            </Heading>
            <SimpleGrid columns={[1, 1, 1, 2]} spacingY={5}>
              <Flex
                flexDir={'column'}
                w={[240, '80vw', 540, '80%', 440]}
                h={[280, 'full', 'full', 320]}
                py={5}
                whiteSpace={'break-spaces'}
              >
                <QuixgoText fontSize={['lg', '5xl']} fontWeight={'bold'} />
                <Text color={'whiteAlpha.700'}>
                  Welcome to QUIXGO, your gateway to unparalleled service in the
                  Indian logistics realm. We offers the best commercial rates
                  from top courier partners, automated reports, and stress-free
                  deliveries with our NDR feature. Enjoy COD and Prepaid
                  services across 25000+ Pincodes. Your success, our priority.
                </Text>
              </Flex>
              <Flex
                gap={5}
                alignItems={'center'}
                flexDir={['column', 'row']}
                justifyContent={'center'}
              >
                <>
                <Link to="/b2c/dashboard">
                  <LayoutCard
                    heading={'B2C'}
                    text={
                      'Are you tired of handling multiple courier companies for your business shipments? B2C courieraggregation might be the solution you need.'
                    }
                    onClick={handleB2cCardCLick}
                  />
                </Link>
                <Link to="/b2b/dashboard">
                  <LayoutCard
                    heading={'B2B'}
                    text={
                      'The trade between two business entities needs a wide pin code reach as it facilitates the delivery of bulk products at the right time.'
                    }
                    onClick={handleB2bCardCLick}
                  />
                </Link>
               </>
               {loggedUser.userRole!=="CSR" &&
                <Link to={'/allMerchant'}>
                  <LayoutCard
                    heading={'All Customer'}
                    text={
                      'All Customer Details info. Manage Customer Details and Activity Using this Page'
                    }
                    onClick={handleAllMerchantCardCLick}
                  />
                </Link>
}
              </Flex>
            </SimpleGrid>
          </>
        </Flex>
      </Flex>
      <Outlet />
    </Box>
  );
};

export default Layout;
