import { createSlice } from '@reduxjs/toolkit';
export const LeadsDetailsSlice = createSlice({
  name: 'LeadsDetailsSlice',
  initialState: {
    leadsListData: [],
    isLeadsListDataLoading: false,
    isFileUploaded: false,
    editFormData: [],
    commentLeadsListData: [],
    leadsListComment: [],
    isLeadsListCommentLoading: [],
  },
  reducers: {
    setAllLeadsList: (state, action) => {
      state.leadsListData = action.payload;
    },
    setIsLeadsListLoading: (state, action) => {
      state.isLeadsListDataLoading = action.payload;
    },
    setAllLeadsListComment: (state, action) => {
      state.leadsListComment = action.payload;
    },
    setIsLeadsListCommentLoading: (state, action) => {
      state.isLeadsListCommentLoading = action.payload;
    },
    setIsFileUploaded: (state, action) => {
      state.isFileUploaded = action.payload;
    },
    setEditFormData: (state, action) => {
      state.editFormData = action.payload;
    },
    setCommentLeadsListData: (state, action) => {
      state.commentLeadsListData = action.payload;
    },
  },
});
export const {
  setAllLeadsList,
  setIsLeadsListLoading,
  setIsFileUploaded,
  setEditFormData,
  setCommentLeadsListData,
  setAllLeadsListComment,
  setIsLeadsListCommentLoading,
} = LeadsDetailsSlice.actions;

export const getAllLeadsList = state => state.LeadsDetailsSlice.leadsListData;
export const getAllLeadsListComment = state =>
  state.LeadsDetailsSlice.leadsListComment;
export const getIsLeadsListLoading = state =>
  state.LeadsDetailsSlice.isLeadsListDataLoading;
export const getIsLeadsListCommentLoading = state =>
  state.LeadsDetailsSlice.isLeadsListCommentLoading;
export const getIsFileUploaded = state =>
  state.LeadsDetailsSlice.isFileUploaded;
export const getEditFormData = state => state.LeadsDetailsSlice.editFormData;
export const getCommentLeadsListData = state =>
  state.LeadsDetailsSlice.commentLeadsListData;
///we can also write thunks by hand , which may contain both sync and async logic

export default LeadsDetailsSlice.reducer;
