import { createSlice } from '@reduxjs/toolkit';
export const SettingsDetailsSlice = createSlice({
  name: 'PartnerDetailsSlice',
  initialState: {
    offerCodeList: [],
    OfficeLocationList: [],
    DepartmentList: [],
    DesignationList: [],
    idTypeList: [],
    b2bConfig:{},
    b2cConfig:{},
    isLoading:false
  },
  reducers: {
    setOfferCodeList: (state, action) => {
      state.offerCodeList = action.payload;
    },
    setOfficeLocationList: (state, action) => {
      state.OfficeLocationList = action.payload;
    },
    setDepartmentList: (state, action) => {
      state.DepartmentList = action.payload;
    },
    setDesignationList: (state, action) => {
      state.DesignationList = action.payload;
    },
    setidTypeList: (state, action) => {
      state.idTypeList = action.payload;
    },
    setB2BConfig:(state,action)=>{
      state.b2bConfig=action.payload;
    },
    setB2CConfig:(state,action)=>{
      state.b2cConfig=action.payload;
    },
    setConfigLoading:(state,action)=>{
      state.isLoading=action.payload;
    }
  },
});
export const {
  setOfferCodeList,
  setOfficeLocationList,
  setDepartmentList,
  setDesignationList,
  setidTypeList,
  setB2BConfig,
  setB2CConfig,
  setConfigLoading
} = SettingsDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getOfferCodeList = state =>
  state.SettingsDetailsSlice.offerCodeList;
export const getOfficeLocationList = state =>
  state.SettingsDetailsSlice.OfficeLocationList;
export const getDepartmentList = state =>
  state.SettingsDetailsSlice.DepartmentList;
export const getDesignationList = state =>
  state.SettingsDetailsSlice.DesignationList;
export const getidTypeList = state => state.SettingsDetailsSlice.idTypeList;
export const getB2bConfig = (state) =>
  state.SettingsDetailsSlice.b2bConfig;
export const getB2cConfig = (state) =>
  state.SettingsDetailsSlice.b2cConfig;
export const getConfigLoading=(state)=>
  state.SettingsDetailsSlice.isLoading;

///we can also write thunks by hand , which may contain both sync and async logic

export default SettingsDetailsSlice.reducer;
