import React, { lazy, Suspense } from 'react';
import RouteFallback from './fallback.routes';
const PrivateLayout = lazy(() => import('../layouts/private.layouts'));
const MerchantsPage = lazy(() => import('../pages/Merchants.page'));
const B2cShipmentPage = lazy(() => import('../pages/B2cShipment.page'));
const DashboardPage = lazy(() => import('../pages/Dashboard.page'));
const ShippingPartnersPage = lazy(() =>
  import('../pages/Shippingpartners.page')
);
const PickuppointsPage = lazy(() => import('../pages/Pickuppoints.page'));
const BillingPage = lazy(() => import('../pages/Billing.page'));
const SettingsPage = lazy(() => import('../pages/Settings.page'));
const ManageInvoicePage = lazy(() => import('../pages/ManageInvoice.page'));
const ManageUsersPage = lazy(() => import('../pages/ManageUsers.page'));
const CustomerDetails = lazy(() => import('../pages/CustomerDetails.page'));
const AwbStatusPage = lazy(() => import('../pages/AwbStatus.page'));
const CalculatePage = lazy(() => import('../pages/Calculator.page'));
const InsurancePage = lazy(() => import('../pages/Insurance.page'));
const WeightManagementPage = lazy(() =>
  import('../pages/WeightManagement.page')
);
const RemittancePage = lazy(() => import('../pages/Remittance.page'));

const RoutesConfig = {
  privateRoute: [
    {
      path: '',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="dashboard">
          <PrivateLayout />
        </Suspense>
      ),
    },
    {
      path: '/dashboard',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="dashboard">
          <DashboardPage />
        </Suspense>
      ),
    },
    {
      path: '/merchants',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <MerchantsPage />
        </Suspense>
      ),
    },
    {
      path: '/shipmentstatus',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <B2cShipmentPage />
        </Suspense>
      ),
    },
    {
      path: '/shippingpartners',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <ShippingPartnersPage />
        </Suspense>
      ),
    },
    {
      path: '/pickuppoints',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <PickuppointsPage />
        </Suspense>
      ),
    },
    {
      path: '/billing',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <BillingPage />
        </Suspense>
      ),
    },
    {
      path: '/manageinvoices',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <ManageInvoicePage />
        </Suspense>
      ),
    },
    {
      path: '/manageusers',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <ManageUsersPage />
        </Suspense>
      ),
    },
    {
      path: '/settings',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <SettingsPage />
        </Suspense>
      ),
    },
    {
      path: '/customerdetails/:customerId',
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <CustomerDetails />
        </Suspense>
      ),
    },
    {
      path: '/status/:awbNumber',
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <AwbStatusPage />
        </Suspense>
      ),
    },
    {
      path: '/calculatePrice',
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <CalculatePage />
        </Suspense>
      ),
    },
    {
      path: '/insurance',
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <InsurancePage />
        </Suspense>
      ),
    },

    {
      path: '/weightmanagement',
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <WeightManagementPage />
        </Suspense>
      ),
    },
    {
      path: '/remittance',
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />}>
          <RemittancePage />
        </Suspense>
      ),
    },
  ],
};

export default RoutesConfig;
