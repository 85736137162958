import { createSlice } from '@reduxjs/toolkit';
export const UserDetailsSlice = createSlice({
  name: 'UserDetailsSlice',
  initialState: {
    userName: '',
    token: '',
    loggedUserDetails: '',
    usersList: [],
    referralCodeList: [],
    UserInfoLoader: false,
  },
  reducers: {
    setUserEmailId: (state, action) => {
      state.userEmailId = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setLoggedUserDetails: (state, action) => {
      state.loggedUserDetails = action.payload;
    },
    setUserInfoLoader: (state, action) => {
      state.UserInfoLoader = action.payload;
    },
    setPasswordChanges: (state, action) => {
      state.isPasswordChanged = action.payload;
    },
    setEmailLoader: (state, action) => {
      state.sendingOtp = action.payload;
    },
    setUserVarifyLoader: (state, action) => {
      state.isUserVarifyLoader = action.payload;
    },
    setUserVarifyStatus: (state, action) => {
      state.isUserVerified = action.payload;
    },
    setUserVarificationError: (state, action) => {
      state.isUserVerificationFailed = action.payload;
    },
    setUserList: (state, action) => {
      state.usersList = action.payload;
    },
    setRefrealCodeList: (state, action) => {
      state.referralCodeList = action.payload;
    },
  },
});
export const {
  setUserName,
  setLoggedUserDetails,
  setUserInfoLoader,
  setPasswordChanges,
  setEmailLoader,
  setUserVarifyLoader,
  setUserVarifyStatus,
  setUserVarificationError,
  setUserEmailId,
  setUserList,
  setRefrealCodeList,
} = UserDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getLoggedUser = state => state.UserDetailsSlice.loggedUserDetails;
export const getUserInfoLoader = state => state.UserDetailsSlice.UserInfoLoader;

export const getOtpVerificationStatus = state =>
  state.UserDetailsSlice.isOtpVerified;

export const getEmailLoader = state => state.UserDetailsSlice.sendingOtp;

export const getUserEmailId = state => state.UserDetailsSlice.userEmailId;

export const getUserVarifyLoader = state =>
  state.UserDetailsSlice.isUserVarifyLoader;

export const getUserVarifyStatus = state =>
  state.UserDetailsSlice.isUserVerified;

export const getUserVerificationError = state =>
  state.UserDetailsSlice.isUserVerificationFailed;

export const getPasswordChanges = state =>
  state.UserDetailsSlice.isPasswordChanged;
export const getUserList = state => state.UserDetailsSlice.usersList;
export const getReferralCodeList = state =>
  state.UserDetailsSlice.referralCodeList;

///we can also write thunks by hand , which may contain both sync and async logic

export default UserDetailsSlice.reducer;
