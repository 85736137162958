import { takeLatest, all, call, put } from 'redux-saga/effects';
// import download from 'js-file-download';
import {
  setOfferCodeList,
  setOfficeLocationList,
  setDepartmentList,
  setDesignationList,
  setidTypeList,
  setB2BConfig,
  setB2CConfig,
  setConfigLoading
} from '../slices/SettingsDetailsSlice';
import {
  httpCall,
  ADD_OFFERCODE_URL,
  GET_ALL_OFFERCODE_URL,
  ADD_OFFICE_LOCATION,
  GET_ALL_OFFICE_LOCATION_DETAILS_URL,
  ADD_DEPARTMENT_DETAILS_URL,
  GET_ALL_DEPARTMENT_DETAILS_URL,
  ADD_DESIGNATION_DETAILS,
  GET_ALL_DESIGNATION_DETAILS_URL,
  GET_ALL_IDTYPE_DETAILS_URL,
  ADD_IDTYPE_DETAILS,
  GET_APP_CONFIG_B2C_URL,
  GET_APP_CONFIG_B2B_URL
} from '../../utils/api';
import { AUTH_USER_CG_TOKEN_KEY } from '../../utils/constant';
import { toast } from 'react-toastify';

// let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
// var qs = require('qs');

export function* fetchAllOfferCodesDetails(payload) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const getOfferCodesUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_OFFERCODE_URL}`;
  try {
    const offerCodesDetails = yield call(httpCall, {
      url: getOfferCodesUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data:data
    });
    if (offerCodesDetails && offerCodesDetails.success) {
      yield put(setOfferCodeList(offerCodesDetails.data));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
export function* addOfferCodeDetails(params) {
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const { payload } = params;
  // console.log(postData);
  // var data = qs.stringify(payload);

  const addOfferCodeUrl = `${process.env.REACT_APP_BASEURL}${ADD_OFFERCODE_URL}`;
  try {
    const offerCodeDetails = yield call(httpCall, {
      url: addOfferCodeUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (offerCodeDetails && offerCodeDetails.success) {
      yield call(fetchAllOfferCodesDetails);
      toast.success('Shipping Partners Added Successfuly', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAllOfficeLocationDetails(payload) {
  const getOfficeLocationDetailsUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_OFFICE_LOCATION_DETAILS_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const officelocationDetail = yield call(httpCall, {
      url: getOfficeLocationDetailsUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data:data
    });
    if (officelocationDetail && officelocationDetail.success) {
      yield put(setOfficeLocationList(officelocationDetail.data));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addOffficeLocationDetails(params) {
  const { payload } = params;
  // console.log(postData);
  // var data = qs.stringify(payload);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const addOffficeLocationUrl = `${process.env.REACT_APP_BASEURL}${ADD_OFFICE_LOCATION}`;
  try {
    const officelocationDetails = yield call(httpCall, {
      url: addOffficeLocationUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (officelocationDetails && officelocationDetails.success) {
      yield call(fetchAllOfficeLocationDetails);
      // yield put(setOfficeLocationList(officelocationDetails.data));
      toast.success('Shipping Partners Added Successfuly', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAllDepartmentDetails(payload) {
  const getDepartmentDetailsUrl = `${process.env.REACT_APP_BASEURL}${GET_ALL_DEPARTMENT_DETAILS_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const departmentDetailRes = yield call(httpCall, {
      url: getDepartmentDetailsUrl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data:data
    });
    if (departmentDetailRes && departmentDetailRes.success) {
      yield put(setDepartmentList(departmentDetailRes.data));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addDepartmentdetails(params) {
  const { payload } = params;
  // console.log(postData);
  // var data = qs.stringify(payload);

  const addDepartmentDetailsUrl = `${process.env.REACT_APP_BASEURL}${ADD_DEPARTMENT_DETAILS_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const departmentDetails = yield call(httpCall, {
      url: addDepartmentDetailsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (departmentDetails && departmentDetails.success) {
      yield call(fetchAllDepartmentDetails);
      // yield put(setOfficeLocationList(officelocationDetails.data));
      toast.success('Shipping Partners Added Successfuly', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAllDesignationDetails(payload) {
  const getDesignationDetailsurl = `${process.env.REACT_APP_BASEURL}${GET_ALL_DESIGNATION_DETAILS_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const designationdetail = yield call(httpCall, {
      url: getDesignationDetailsurl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data:data
    });
    if (designationdetail && designationdetail.success) {
      yield put(setDesignationList(designationdetail.data));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addDesignationdetails(params) {
  const { payload } = params;
  // console.log(postData);
  // var data = qs.stringify(payload);

  const addDesignationDetailsUrl = `${process.env.REACT_APP_BASEURL}${ADD_DESIGNATION_DETAILS}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const designationDetails = yield call(httpCall, {
      url: addDesignationDetailsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (designationDetails && designationDetails.success) {
      yield call(fetchAllDesignationDetails);
      // yield put(setOfficeLocationList(officelocationDetails.data));
      toast.success('Shipping Partners Added Successfuly', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* addidTypeDetails(params) {
  const { payload } = params;
  // console.log(postData);
  // var data = qs.stringify(payload);
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  const addidTypeDetailsUrl = `${process.env.REACT_APP_BASEURL}${ADD_IDTYPE_DETAILS}`;
  try {
    const idTypeDetails = yield call(httpCall, {
      url: addidTypeDetailsUrl,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      data: payload,
    });
    if (idTypeDetails && idTypeDetails.success) {
      yield call(fetchAllIdTypeDetails);
      // yield put(setOfficeLocationList(officelocationDetails.data));
      toast.success('Shipping Partners Added Successfuly', {
        closeOnClick: true,
        draggable: true,
      });
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchAllIdTypeDetails(payload) {
  const getidTypeDetailsurl = `${process.env.REACT_APP_BASEURL}${GET_ALL_IDTYPE_DETAILS_URL}`;
  let token = sessionStorage.getItem(AUTH_USER_CG_TOKEN_KEY);
  try {
    const idTypedetail = yield call(httpCall, {
      url: getidTypeDetailsurl,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      // data:data
    });
    if (idTypedetail && idTypedetail.success) {
      yield put(setidTypeList(idTypedetail.data));
    } else {
      toast.error('Something went wrong', {
        closeOnClick: true,
        draggable: true,
      });
    }
  } catch (err) {
    console.log(err);
  }
}
  
export function* getAppConfig(params) {
  try {
    const { postData:{
      shipmentType
    } } = params;
    yield put(setConfigLoading(true));
    const getBankUrl = `${process.env.REACT_APP_BASEURL}${shipmentType==='B2C'?GET_APP_CONFIG_B2C_URL:GET_APP_CONFIG_B2B_URL}`;
    const appConfigResponse = yield call(httpCall, {
      url: getBankUrl,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      }
    });
    if (appConfigResponse?.success) {
      yield put(shipmentType==='B2C'?setB2CConfig(appConfigResponse.data):setB2BConfig(appConfigResponse.data));
      yield put(setConfigLoading(false));
    } else {
      toast.error(appConfigResponse.err.data, {
        closeOnClick: true,
        draggable: true,
      });
      yield put(setConfigLoading(false));
    }
  } catch (err) {
    yield put(setConfigLoading(false));
    console.log("error", err);
  }
}

export function* watchSettingsDetailsSaga() {
  yield takeLatest('GET_ALL_OFFER_CODES', fetchAllOfferCodesDetails);
  yield takeLatest('ADD_OFFER_CODES', addOfferCodeDetails);

  yield takeLatest('ADD_OFFICE_LOCATION', addOffficeLocationDetails);
  yield takeLatest('GET_ALL_OFFICE_LOCATION', fetchAllOfficeLocationDetails);

  yield takeLatest('GET_ALL_DEPARTMENT_DETAILS', fetchAllDepartmentDetails);
  yield takeLatest('ADD_DEPARTMENT_DETAILS', addDepartmentdetails);

  yield takeLatest('GET_ALL_DESIGNATION_DETAILS', fetchAllDesignationDetails);
  yield takeLatest('ADD_DESIGNATION_DETAILS', addDesignationdetails);

  yield takeLatest('GET_ALL_IDTYPE_DETAILS', fetchAllIdTypeDetails);
  yield takeLatest('ADD_IDTYPE_DETAILS', addidTypeDetails);

  yield takeLatest('GET_APP_CONFIG',getAppConfig);
}

export default function* SettingsDetailsSaga() {
  yield all([watchSettingsDetailsSaga()]);
}
