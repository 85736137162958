import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input
} from "@chakra-ui/react";

const LengthFormControl = ({ register, errors, control }) => {
  return (
    <FormControl isInvalid={errors.length}>
      <FormLabel htmlFor="length">
        Length (cm)
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="length"
        type="number"
        step={"any"}
        placeholder="Enter Length "
        {...register("length", {
          required: "This is required",
        })}
      />

      <FormErrorMessage>{errors?.length?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default LengthFormControl;