import React from "react";
import {
  FormControl,
  FormLabel,
  Text,
  FormErrorMessage,
  Input,
} from "@chakra-ui/react";

const CodAmountFormControl = ({
  register,
  errors,
  control,
  paymentMode = "COD",
  isTopayCodAllowed = false,
  isB2B = true,
}) => {
  return (
    <FormControl isInvalid={errors.codAmount}>
      <FormLabel htmlFor="codAmount">
       {paymentMode==='TOPAY' && isTopayCodAllowed ?"Additional COD" :"COD Amount"}
        <Text as="span" color="red">
          *
        </Text>
      </FormLabel>
      <Input
        control={control}
        id="codAmount"
        type="number"
        placeholder="Enter COD Amount"
        {...register("codAmount", {
          required: "This is required",
        })}
      />
      <FormErrorMessage>{errors?.codAmount?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default CodAmountFormControl;