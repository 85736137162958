import { createSlice } from '@reduxjs/toolkit';
export const TransactionDetailsSlice = createSlice({
  name: 'TransactionDetailsSlice',
  initialState: {
    transactionList: {},
    isTransactionDetailsLoading: false,
    merchantTransactionList: [],
    remittancePaidList: [],
    isInvoiceLoading: false,
    invoiceDate: null,
  },
  reducers: {
    setMerchantTransactionList: (state, action) => {
      state.merchantTransactionList = action.payload;
    },
    setAllTransactionList: (state, action) => {
      state.transactionList = action.payload;
    },
    setTransactionDetailsLoading: (state, action) => {
      state.isTransactionDetailsLoading = action.payload;
    },
    setIsInvoiceLoading: (state, action) => {
      state.isInvoiceLoading = action.payload;
    },
    setRemittancePaidList: (state, action) => {
      state.remittancePaidList = action.payload;
    },
    setInvoiceDate: (state, action) => {
      state.invoiceDate = action.payload;
    },
  },
});
export const {
  setMerchantTransactionList,
  setAllTransactionList,
  setTransactionDetailsLoading,
  setRemittancePaidList,
  setIsInvoiceLoading,
  setInvoiceDate,
} = TransactionDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getMerchantTransactionList = (state, action) =>
  state.TransactionDetailsSlice.merchantTransactionList;
export const getAllTransactionList = state =>
  state.TransactionDetailsSlice.transactionList;
export const getTransactionDetailsLoading = state =>
  state.TransactionDetailsSlice.isTransactionDetailsLoading;
export const getIsInvoiceLoading = state =>
  state.TransactionDetailsSlice.isInvoiceLoading;
export const getRemittancePaidList = state =>
  state.TransactionDetailsSlice.remittancePaidList;
export const getInvoiceDate = state =>
  state.TransactionDetailsSlice.invoiceDate;
///we can also write thunks by hand , which may contain both sync and async logic

export default TransactionDetailsSlice.reducer;
