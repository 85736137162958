import {createSlice} from '@reduxjs/toolkit';
export const PartnerDetailsSlice=createSlice({
    name:'PartnerDetailsSlice',
    initialState:{
        ShippingPartners:[],
        PriceList:[],
        isPriceLoading:false,
    },
    reducers:{
        setShippingPartners:(state,action)=>{
            state.ShippingPartners=action.payload;
        },
        setPriceList:(state,action)=>{
            state.PriceList=action.payload;
        },
        setIsPriceLoading:(state,action)=>{
            state.IsPriceLoading=action.payload;
        }
    }
});
export const {
    setShippingPartners,
    setPriceList,
    setIsPriceLoading,
} = PartnerDetailsSlice.actions;
// the function below is called a selector and allows us to select a value from the state.
// selector can be defined inline where they can be used instead of in the slice file.

export const getShippingPartners=(state)=>
    state.PartnerDetailsSlice.ShippingPartners;
export const getPriceList=(state)=>
    state.PartnerDetailsSlice.PriceList; 
export const getIsPriceLoading=(state)=>
    state.PartnerDetailsSlice.IsPriceLoading;
///we can also write thunks by hand , which may contain both sync and async logic

export default PartnerDetailsSlice.reducer;
